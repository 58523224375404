import { useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Col, DropdownItem, Row } from "reactstrap";
import colors from "../config/colors.config";
import NumeroTools from "../tools/Numero.tool";
import timeTool from "../tools/time.tool";
import logo from "../assets/image/logo/logoTzedaka.jpg";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const SolicitudCreditoPDF = ({ cliente, solicitudCredito }) => {
    let [usuario, setUsuario] = useState("")
    const styles = StyleSheet.create({
        titulos: {
            fontWeight: "bold",
            fontSize: "14px"
        },
        subTitulos: {
            fontWeight: "bold",
            fontSize: "12px",

        },
        contenedor: {
            border: "1px solid black",
            marginRight: "15px",
            marginLeft: "15px",
        },
        table: {
            display: "table",
            width: "auto",
            // borderStyle: "solid",
            // borderWidth: 1,
            // borderColor:"#000",
            // backgroundColor: "#E2E2E2",
            borderRightWidth: 0,
            borderBottomWidth: 0,
            border: "none"
        },
        tableRow: {
            // margin: "auto",
            flexDirection: "row",

        },
        tableCol: {
            width: "25%",
            // borderStyle: "solid",
            // borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },

        tableCell_2: {
            // margin: "auto",
            // marginTop: 5,
            fontSize: 10,
            textAlign: "left",
            color: "#353535",
            textAlign: 'justify',
        },
        tableCell: {
            // margin: "auto",
            // marginTop: 5,
            fontSize: 10,
            textAlign: "left",
            textAlign: 'justify',
        },

        footer_right: {
            position: 'absolute',
            bottom: 35,
            left: 40,
            right: 40,
            textAlign: 'right',
            fontSize: 10,
        },
        footer_left: {
            position: 'absolute',
            bottom: 35,
            left: 40,
            right: 40,
            textAlign: 'left',
            fontSize: 10,
        },
        footer_firma: {
            position: 'absolute',
            bottom: 75,
            left: 40,
            right: 40,
            textAlign: 'left',
            fontSize: 10,
        }
    });

    useEffect(() => {
        let usuario = cookies.get("usuario", { path: "/" })

        setUsuario(usuario.nombreCompletoUsuario);
    }, []);

    return (
        <Document
            title={"Solicitud Crédito de " + cliente.nombreCliente + " " + cliente.apellidoPaternoCliente + " " + cliente.apellidoMaternoCliente}

        >
            <Page
                size="A4"
                wrap
                fixed
            >
                <View>
                    <Row
                        style={{
                            color: colors.blanco,
                            marginRight: "15px",
                            marginLeft: "15px",
                        }}
                    >
                        <Col>

                            <Image
                                src={logo}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    height: "40",
                                    width: "75px",
                                    marginTop: "20px"
                                }}
                            />

                        </Col>
                        <Col
                            style={{
                                textAlign: "center",
                                marginTop: "-40px"
                            }}
                        >
                            <Text style={{ color: colors.negro, fontWeight: "bold" }} >Operadora Tzedaká, S.A.P.I. DE C.V</Text>
                            <Text style={{ color: colors.negro, fontWeight: "bold", marginTop: "5px" }} >Solicitud de Crédito</Text>
                        </Col>
                    </Row>
                </View>


                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                        marginTop: "30px"
                    }}
                >
                    <Col>
                        <Text style={styles.titulos} >Datos Generales</Text>
                    </Col>
                </Row>

                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre Completo:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Numero Teléfono:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Correo Electrónico:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>País Nacimiento:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreCliente + " " + cliente.apellidoPaternoCliente + " " + cliente.apellidoMaternoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.numeroTelefonoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.correoElectronicoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombrePaisNacimientoCliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Estado de Nacimiento:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Ciudad de Nacimiento:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nivel de Estudio:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Estado Civil:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreEstadoNacimientoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreCiudadNacimientoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nivelEstudioCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreEstadoCivilCliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Clave Lector:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>INE Folio:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>INE Numero:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>CURP:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.claveElectorCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.INEFolioCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.INENumeroCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.CURPCliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>RFC:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>País Donde Vives:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Estado Donde Vives:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Ciudad Donde Vives:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.RFCCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombrePaisViviendaCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreEstadoViviendaCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreCiudadViviendaCliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Localidad donde Vives:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Calle y Numero de Casa:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Tipo de Vivienda:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre del Cónyuge:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreLocalidadCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.calle_y_numeroCasaCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.descripcionTipoViviendaCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreConyugueCliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Dependientes Económicos:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Renta:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Salud:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.dependientesEconimicosCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosRentaCasa)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosEducacionCasa)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosSaludCasa)}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Dependientes Agua:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Luz:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Gastos de Internet:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Otros Gastos:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosAguaCasa)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosLuzCasa)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosInternetCasa)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosOtrosCasa)}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>

                                        <Text style={styles.tableCell}>Fecha de Nacimiento:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>*Edad Estimada*:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{timeTool.castFechaNacimiento(cliente.fechaNacimientoCliente == undefined ? "" : cliente.fechaNacimientoCliente)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{timeTool.calcularEdad(cliente.fechaNacimientoCliente == undefined ? "" : cliente.fechaNacimientoCliente)}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                    </div>
                </Row>
                <div style={{ height: "15px" }} ></div>
                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                    }}
                >
                    <Col>
                        <Text style={styles.titulos} >
                            {
                                cliente.esTrabajadorCliente == 1 ?
                                    "Datos del Empleo"
                                    :
                                    cliente.tieneNegocioCliente == 1 ?
                                        "Datos del Negocio"
                                        :
                                        cliente.esTrabajadorCliente == 0 && cliente.tieneNegocioCliente == 0 ?
                                            "La persona no especifico si es trabajador o cliente"
                                            :
                                            "Algo No Esta Bien..."
                            }
                        </Text>
                    </Col>
                </Row>


                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >
                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Puesto/Categoría:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Teléfono:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dirección:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Punto de Referencia:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.puestoCategoriaTrabajoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.numeroTelefonoNegocioCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.direccionTrabajoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.puntpReferenciaTrabajoCliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{cliente.esTrabajadorCliente === 1 ? "Tiempo con tu Negocio" : "Antigüedad en tu Trabajo"}:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Ingreso Mensual:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dias Que Labora:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Horario De Trabajo:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.antiguedadTrabajoCliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.ingresoMensualTrabajoCliente)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>
                                            {cliente.trabajaLunesNegocioCliente == undefined ? "" : cliente.trabajaLunesNegocioCliente == 1 ? "Lunes" : ""}
                                            {cliente.trabajaMartesNegocioCliente == undefined ? "" : cliente.trabajaMartesNegocioCliente == 1 ? ", Martes" : ""}
                                            {cliente.trabajaMiercolesNegocioCliente == undefined ? "" : cliente.trabajaMiercolesNegocioCliente == 1 ? ", Miercoles" : ""}
                                            {cliente.trabajaJuevesNegocioCliente == undefined ? "" : cliente.trabajaJuevesNegocioCliente == 1 ? ", Jueves" : ""}
                                            {cliente.trabajaViernesNegocioCliente == undefined ? "" : cliente.trabajaViernesNegocioCliente == 1 ? ", Viernes" : ""}
                                            {cliente.trabajaSabadoNegocioCliente == undefined ? "" : cliente.trabajaSabadoNegocioCliente == 1 ? ", Sabado" : ""}
                                            {cliente.trabajaDomingoNegocioCliente == undefined ? "" : cliente.trabajaDomingoNegocioCliente == 1 ? ", Domingo" : ""}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>
                                            {
                                                timeTool.formato12Horas(cliente.horaEntradaTrabajoNegocio == undefined ?
                                                    ""
                                                    :
                                                    cliente.horaEntradaTrabajoNegocio)} a {
                                                timeTool.formato12Horas(cliente.horaSalidaTrabajoNegocio == undefined ?
                                                    ""
                                                    :
                                                    cliente.horaSalidaTrabajoNegocio)
                                            }
                                        </Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                    </div>

                </Row>
                <div style={{ height: "15px" }} ></div>
                {
                    cliente.tieneNegocioCliente === 1 ?
                        <>
                            <Row
                                style={{
                                    marginRight: "15px",
                                    marginLeft: "15px",
                                }}
                            >
                                <Col>
                                    <Text style={styles.titulos} >
                                        Análisis del Negocio
                                    </Text>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "15px",
                                    marginLeft: "15px",
                                    borderRadius: "8px"
                                }}
                            >
                                <div style={{ border: "1px solid black" }} >
                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Ventas Semanales:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Inversion Semanal:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Tipo Local:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Renta de tu Negocio:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.ventasSemanalesNegocioCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.inversionSemanalNegocioCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.descripcionLocalPropioRentado)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosRentaNegocioCliente)}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Gastos de Luz:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Gastos de Internet:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Otros Gastos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Cuanto Tiene Mercancía:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosLuzNegocioCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosInternetNegocioCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.gastosOtrosNegocioCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.cuantoTieneMercanciaCliente)}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Dinero Que Le Deben:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Dinero Ahorrado:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Ganancia Negocio:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Tiene Otro Ingresos:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.cuantoDineroDebenCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.cuantoTieneEfectivoAhorradoCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.cuantoTieneGananciaNegocio}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.cuantoTieneMercanciaCliente == 1 ? "Si" : "No"}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Descripción Otros Ingresos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Declaración Patrimonial Vivienda:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Declaración Patrimonial Vehículos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Declaración Patrimonial Equipo:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.descripcionOtrosIngresosClientes}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.viviendaDeclaracionPatrimonialCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.vehiculosDeclaracionPatrimonialCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.mobiliarioEquipoDeclaracionPatrimonial)}</Text>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>

                                    <View style={styles.table}>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Ha Tenido Créditos:</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Institución Donde le Dieron el Crédito:</Text>
                                                </View>
                                            </View>
                                        </Row>
                                        <Row
                                            style={{
                                                marginRight: "5px",
                                                marginLeft: "5px",
                                                marginTop: "5px"
                                            }}
                                        >
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{cliente.haTenidoCreditoBancariosCliente == 1 ? "Si" : "No"}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(cliente.institucionDieronCreditoCliente)}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                </View>
                                            </View>
                                        </Row>
                                    </View>
                                </div>

                            </Row>
                        </>
                        :
                        null
                }
                <View style={styles.footer_left}>
                    <Text>Documento generado por: {usuario}</Text>
                </View>
                <View style={styles.footer_right}>
                    <Text>Pagina 1 de 2</Text>
                </View>
            </Page >
            <Page
                size="A4"
                wrap
            >
                <div style={{ height: "50px" }} ></div>
                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                    }}
                >
                    <Col>
                        <Text style={styles.titulos} >
                            Referencias De Personas Que No Vivan Con Usted (2 Personas)
                        </Text>
                    </Col>

                </Row>
                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre Completo y Apellido:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Parentesco:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dirección:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Teléfono:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreCompletoPersonaReferencia1Cliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.parentescoPersonaReferencia1Cliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.direccionReferencia1Cliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.numeroTelefonoPersonaReferencia1Cliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Nombre Completo y Apellido:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Parentesco:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Dirección:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Teléfono:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.nombreCompletoPersonaReferencia2Cliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.parentescoPersonaReferencia2Cliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.direccionReferencia2Cliente}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{cliente.numeroTelefonoPersonaReferencia2Cliente}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                    </div>
                </Row>
                <div style={{ height: "15px" }} ></div>
                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                    }}
                >
                    <Col>
                        <Text style={styles.titulos} >
                            Datos del Crédito que Solicita
                        </Text>
                    </Col>

                </Row>
                <Row
                    style={{
                        marginRight: "15px",
                        marginLeft: "15px",
                        borderRadius: "8px"
                    }}
                >
                    <div style={{ border: "1px solid black" }} >

                        <View style={styles.table}>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Tipo de Crédito:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Monto Solicitado:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Plazo del Crédito:</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>Forma de Pago:</Text>
                                    </View>
                                </View>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{solicitudCredito.IdTipoSolicitudCredito == 1 ? "Individual" : solicitudCredito.IdTipoSolicitudCredito == 2 ? "Grupal" : "########"}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(solicitudCredito.monto)}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{solicitudCredito.plazoSolicitudCredito}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCell_2}>{solicitudCredito.formaPagoSolicitudCredito}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        {
                            solicitudCredito.IdStatusSolicitudCredito == 2 ?
                                <View style={styles.table}>
                                    <Row
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>Monto Aprobado:</Text>
                                            </View>
                                        </View>
                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell_2}>{NumeroTools.castFormatoMoneda(solicitudCredito.montoAprobadoSolicitudCredito)}</Text>
                                            </View>
                                        </View>
                                    </Row>
                                </View>
                                :
                                null
                        }

                        <View style={styles.table} >
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View
                                        style={StyleSheet.create({
                                            width: "100%",
                                            // borderStyle: "solid",
                                            // borderWidth: 1,
                                            borderLeftWidth: 0,
                                            borderTopWidth: 0
                                        })}
                                    >
                                        <Text style={styles.tableCell}>Fecha y Hora De Creación De la Solicitado De Credito:</Text>
                                    </View>

                                </View>

                            </Row>
                            <Row
                                style={{
                                    marginRight: "5px",
                                    marginLeft: "5px",
                                    marginTop: "5px"
                                }}
                            >
                                <View style={styles.tableRow}>
                                    <View
                                        style={StyleSheet.create({
                                            width: "100%",
                                            // borderStyle: "solid",
                                            // borderWidth: 1,
                                            borderLeftWidth: 0,
                                            borderTopWidth: 0
                                        })}
                                    >
                                        <Text style={styles.tableCell_2}>{timeTool.castFechaNacimientoEscrita(solicitudCredito.fechaCreacionSolicitudCredito) + " " + timeTool.formato12Horas(solicitudCredito.horaCreacionSolicitudCredito)}</Text>
                                    </View>
                                </View>
                            </Row>
                        </View>
                        {
                            solicitudCredito.IdStatusSolicitudCredito == 2 || solicitudCredito.IdStatusSolicitudCredito == 3 || solicitudCredito.IdStatusSolicitudCredito == 4 ?

                                <View style={styles.table} >
                                    <Row
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <View style={styles.tableRow}>
                                            <View
                                                style={StyleSheet.create({
                                                    width: "100%",
                                                    // borderStyle: "solid",
                                                    // borderWidth: 1,
                                                    borderLeftWidth: 0,
                                                    borderTopWidth: 0
                                                })}
                                            >
                                                <Text style={styles.tableCell}>Fecha y Hora De {solicitudCredito.IdStatusSolicitudCredito == 2 ? "Aprobación" : solicitudCredito.IdStatusSolicitudCredito == 3 ? "Rechazo" : solicitudCredito.IdStatusSolicitudCredito == 4 ? "Cancelado" : "########"} De la Solicitado De Credito:</Text>
                                            </View>

                                        </View>

                                    </Row>
                                    <Row
                                        style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                            marginTop: "5px"
                                        }}
                                    >
                                        <View style={styles.tableRow}>
                                            <View
                                                style={StyleSheet.create({
                                                    width: "100%",
                                                    // borderStyle: "solid",
                                                    // borderWidth: 1,
                                                    borderLeftWidth: 0,
                                                    borderTopWidth: 0
                                                })}
                                            >
                                                <Text style={styles.tableCell_2}>{timeTool.castFechaNacimientoEscrita(solicitudCredito.fechaAprobacionRechazadoModificacion) + " " + timeTool.formato12Horas(solicitudCredito.horaAprobacionRechazadoModificacion)}</Text>
                                            </View>
                                        </View>
                                    </Row>
                                </View>
                                :
                                null
                        }
                    </div>
                </Row>
                <View style={StyleSheet.create({
                    position: 'absolute',
                    bottom: 75,
                    left: 15,
                    right: 15,
                    textAlign: 'left',
                    fontSize: 10,
                })}
                >
                    <Text
                        style={{
                            textAlign: "justify",
                            fontSize: "12px",
                            //marginRight: "15px",
                            //marginLeft: "15px"
                        }}
                    >
                        Por este medio autorizo expresamente a Operadora Tzedaká S.A.P.I de C.V. para que por conducto de sus colaboradores lleve a cabo investigaciones
                        sobre mi comportamiento e historial crediticio, así como cualquier otra información de naturaleza análoga en las sociedades de información Crediticia
                        que estime conveniente; así mismo declaro que conozco la naturaleza y alcance de la información, así como el uso que Operadora Tzedaká S.A.P.I de C.V.
                        hará de la información y del hecho que Operadora Tzedaká S.A.P.I de C.V. podrá realizar consultas periódicas de mi historial crediticio durante el tiempo
                        que mantenga relación jurídica. Estoy consciente y autorizo que este documento quede en propiedad de Operadora Tzedaká S.A.P.I de C.V. para efectos de
                        control y cumplimiento de la ley para regular a las sociedades de Información Crediticia, asi mismo declaro bajo protesta de decir la verdad que la información
                        compartida en esta encuesta es verídica y comprobable.
                    </Text>
                    <Text
                        style={{
                            textAlign: "center",
                            fontSize: "10px",
                            marginTop: "20px"
                        }}
                    >
                        _____________________________________
                    </Text>
                    <Text
                        style={{
                            textAlign: "center",
                            fontSize: "10px",
                            marginTop: "10px"
                        }}
                    >
                        {cliente.nombreCliente + " " + cliente.apellidoPaternoCliente + " " + cliente.apellidoMaternoCliente}
                    </Text>
                </View>
                <View style={styles.footer_left}>
                    <Text>Documento generado por: {usuario}</Text>
                </View>
                <View style={styles.footer_right}>
                    <Text>Pagina 2 de 2</Text>
                </View>
            </Page>
        </Document >
    );
}

export default SolicitudCreditoPDF;