const getDataGenericClient = () => {
    let client = {
        IdCliente: "",
        nombreCliente: "",
        apellidoPaternoCliente: "",
        apellidoMaternoCliente: "",
        correoElectronicoCliente: "",
        IdPaisNacimientoCliente: 0,
        IdEstadoNacimientoCliente: 0,
        IdCiudadNacimientoCliente: 0,
        IdEstadoCivilCliente: 0,
        claveElectorCliente: "",
        INEFolioCliente: "",
        INENumeroCliente: "",
        CURPCliente: "",
        RFCCliente: "",
        IdPaisViviendaCliente: 0,
        IdEstadoViviendaCliente: 0,
        IdCiudadViviendaCliente: 0,
        IdLocalidadViviendaCliente: 0,
        dependientesEconimicosCliente: "",
        IdTipoViviendaCliente: 0,
        IdNivelEstudioCliente: 0,
        nombreConyugueCliente: "",
        numeroTelefonoCliente: "",
        gastoRentaViviendaCliente: 0,
        gastoInternetViviendaCliente: 0,
        gastoLuzViviendaCliente: 0,
        gastoEducacionViviendaCliente: 0,
        gastosSaludViviendaCliente: 0,
        gastosOtrosViviendaCliente: 0,
        esTrabajadorCliente: 0,
        tieneNegocioCliente: 0,
        nombreLugarTrabajoCliente: "",
        puestoCategoriaTrabajoCliente: "",
        numeroTelefonoTrabajoCliente: "",
        direccionTrabajoCliente: "",
        puntpReferenciaTrabajoCliente: "",
        antiguedadTrabajoCliente: "",
        ingresoMensualTrabajoCliente: 0,
        nombreNegocioCliente: "",
        actividadServicioNegocioCliente: "",
        ventasSemanalesNegocioCliente: 0,
        inversionSemanalNegocioCliente: 0,
        IdTipoLocalNegocioCliente: 0,
        gastosRentaNegocioCliente: 0,
        gastosAguaNegocioCliente: 0,
        gastosLuzNegocioCliente: 0,
        gastosInternetNegocioCliente: 0,
        gastosOtrosNegocioCliente: 0,
        mobiliarioEquipoNegocioCliente: 0,
        cuantoTieneMercanciaCliente: 0,
        cuantoDineroDebenCliente: 0,
        cuantoTieneEfectivoAhorradoCliente: 0,
        cuantoTieneGananciaNegocio: 0,
        trabajaLunesNegocioCliente: "",
        trabajaMartesNegocioCliente: 0,
        trabajaMiercolesNegocioCliente: 0,
        trabajaJuevesNegocioCliente: 0,
        trabajaViernesNegocioCliente: 0,
        trabajaSabadoNegocioCliente: 0,
        trabajaDomingoNegocioCliente: 0,
        trabajaLunesTrabajoCliente: 0,
        trabajaMartesTrabajoCliente: 0,
        trabajaMiercolesTrabajoCliente: 0,
        trabajaJuevesTrabajoCliente: 0,
        trabajaViernesTrabajoCliente: 0,
        trabajaSabadoTrabajoCliente: 0,
        trabajaDomingoTrabajoCliente: 0,
        numeroTelefonoNegocioCliente: "",
        tieneOtrosIngresosCliente: 0,
        descripcionOtrosIngresosClientes: "",
        viviendaDeclaracionPatrimonialCliente: 0,
        vehiculosDeclaracionPatrimonialCliente: 0,
        mobiliarioEquipoDeclaracionPatrimonial: 0,
        otrosDeclaracionPatrimonialCliente: 0,
        haTenidoCreditoBancariosCliente: 0,
        institucionDieronCreditoCliente: "",
        montoCreditoCliente: 0,
        nombreCompletoPersonaReferencia1Cliente: "",
        parentescoPersonaReferencia1Cliente: "",
        numeroTelefonoPersonaReferencia1Cliente: "",
        nombreCompletoPersonaReferencia2Cliente: "",
        parentescoPersonaReferencia2Cliente: " ",
        numeroTelefonoPersonaReferencia2Cliente: "",
        calle_y_numeroCasaCliente: "",
        gastosRentaCasa: 0,
        gastosEducacionCasa: 0,
        gastosSaludCasa: 0,
        gastosAguaCasa: 0,
        gastosLuzCasa: 0,
        gastosInternetCasa: 0,
        gastosOtrosCasa: 0,
        descripcionLocalPropioRentado: "",
        horaEntradaTrabajoNegocio: "00:00:00",
        horaSalidaTrabajoNegocio: "00:00:00",
        direccionReferencia1Cliente: "",
        direccionReferencia2Cliente: "",
        fechaNacimientoCliente: "1980-01-01",
        imgFotoPersona: "",
        imgFachadaCasa: "",
        imgINE: "",
        IdReligion: 0,
        descripcionOtraReligion: ""
    }
    // let client = {
    //     "IdCliente": "",
    //     "nombreCliente": "LUIS DONATO",
    //     "apellidoPaternoCliente": "MARTINEZ",
    //     "apellidoMaternoCliente": "SIMUTA",
    //     "correoElectronicoCliente": "Alberttovalentine19@gmail.com",
    //     "IdPaisNacimientoCliente": 1,
    //     "IdEstadoNacimientoCliente": 7,
    //     "IdCiudadNacimientoCliente": 7101,
    //     "IdEstadoCivilCliente": 5,
    //     "claveElectorCliente": "PRGMOS96032707H200",
    //     "INEFolioCliente": "2521530497",
    //     "INENumeroCliente": "097806909",
    //     "CURPCliente": "PEGO960327HCSRMS04",
    //     "RFCCliente": "PEGO960327kk4",
    //     "IdPaisViviendaCliente": 1,
    //     "IdEstadoViviendaCliente": 7,
    //     "IdCiudadViviendaCliente": 7101,
    //     "IdLocalidadViviendaCliente": 76184,
    //     "dependientesEconimicosCliente": "-",
    //     "IdTipoViviendaCliente": 2,
    //     "IdNivelEstudioCliente": 4,
    //     "nombreConyugueCliente": "-",
    //     "numeroTelefonoCliente": "9613629345",
    //     "gastoRentaViviendaCliente": 0,
    //     "gastoInternetViviendaCliente": 0,
    //     "gastoLuzViviendaCliente": 0,
    //     "gastoEducacionViviendaCliente": 0,
    //     "gastosSaludViviendaCliente": 0,
    //     "gastosOtrosViviendaCliente": 0,
    //     "esTrabajadorCliente": 1,
    //     "tieneNegocioCliente": 0,
    //     "nombreLugarTrabajoCliente": "",
    //     "puestoCategoriaTrabajoCliente": "SUBGERENTE",
    //     "numeroTelefonoTrabajoCliente": "",
    //     "direccionTrabajoCliente": "OCALLE DIEGO DE MAZARIEGOS COLONIA CENTRO, SAN CRISTOBAL DE LAS CASAS",
    //     "puntpReferenciaTrabajoCliente": "FRENTE AL PARQUE CENTRAL A LADO DE BANCOMER",
    //     "antiguedadTrabajoCliente": "8 MESES",
    //     "ingresoMensualTrabajoCliente": 12500,
    //     "nombreNegocioCliente": "",
    //     "actividadServicioNegocioCliente": "",
    //     "ventasSemanalesNegocioCliente": 0,
    //     "inversionSemanalNegocioCliente": 0,
    //     "IdTipoLocalNegocioCliente": 0,
    //     "gastosRentaNegocioCliente": 0,
    //     "gastosAguaNegocioCliente": 0,
    //     "gastosLuzNegocioCliente": 0,
    //     "gastosInternetNegocioCliente": 0,
    //     "gastosOtrosNegocioCliente": 0,
    //     "mobiliarioEquipoNegocioCliente": 0,
    //     "cuantoTieneMercanciaCliente": 0,
    //     "cuantoDineroDebenCliente": 0,
    //     "cuantoTieneEfectivoAhorradoCliente": 0,
    //     "cuantoTieneGananciaNegocio": "0",
    //     "trabajaLunesNegocioCliente": 0,
    //     "trabajaMartesNegocioCliente": 0,
    //     "trabajaMiercolesNegocioCliente": 1,
    //     "trabajaJuevesNegocioCliente": 1,
    //     "trabajaViernesNegocioCliente": 1,
    //     "trabajaSabadoNegocioCliente": 1,
    //     "trabajaDomingoNegocioCliente": 1,
    //     "trabajaLunesTrabajoCliente": 0,
    //     "trabajaMartesTrabajoCliente": 0,
    //     "trabajaMiercolesTrabajoCliente": 0,
    //     "trabajaJuevesTrabajoCliente": 0,
    //     "trabajaViernesTrabajoCliente": 0,
    //     "trabajaSabadoTrabajoCliente": 0,
    //     "trabajaDomingoTrabajoCliente": 0,
    //     "numeroTelefonoNegocioCliente": "9613629345",
    //     "tieneOtrosIngresosCliente": 0,
    //     "descripcionOtrosIngresosClientes": "",
    //     "viviendaDeclaracionPatrimonialCliente": 0,
    //     "vehiculosDeclaracionPatrimonialCliente": 0,
    //     "mobiliarioEquipoDeclaracionPatrimonial": 0,
    //     "otrosDeclaracionPatrimonialCliente": 0,
    //     "haTenidoCreditoBancariosCliente": 0,
    //     "institucionDieronCreditoCliente": "",
    //     "montoCreditoCliente": 0,
    //     "nombreCompletoPersonaReferencia1Cliente": "KETMIA FABIOLA NUCAMENDI BRISEÑO",
    //     "parentescoPersonaReferencia1Cliente": "AMIGA",
    //     "numeroTelefonoPersonaReferencia1Cliente": "9612473973",
    //     "nombreCompletoPersonaReferencia2Cliente": "AMELIA BERMUDEZ HERNANDEZ",
    //     "parentescoPersonaReferencia2Cliente": "AMIGA",
    //     "numeroTelefonoPersonaReferencia2Cliente": "9671275690",
    //     "calle_y_numeroCasaCliente": "CALLE ESMERALDA MZ 3 LT 35 ",
    //     "gastosRentaCasa": 0,
    //     "gastosEducacionCasa": 0,
    //     "gastosSaludCasa": 0,
    //     "gastosAguaCasa": 5250,
    //     "gastosLuzCasa": 230,
    //     "gastosInternetCasa": 739,
    //     "gastosOtrosCasa": 1500,
    //     "descripcionLocalPropioRentado": "0",
    //     "horaEntradaTrabajoNegocio": "16:00:00",
    //     "horaSalidaTrabajoNegocio": "04:00:00",
    //     "direccionReferencia1Cliente": "CALLE SELENITA 136 JARDINES DEL PEDREGAL PRIMERA SECCION",
    //     "direccionReferencia2Cliente": "CALLE JOSE NERVO NUMERO 7 COLONIA ONCE CUARTOS SAN CRISTOBAL DE LAS CASAS",
    //     "fechaNacimientoCliente": "1996-03-27",
    //     "imgFotoPersona": null,
    //     "imgFachadaCasa": null,
    //     "imgINE": null,
    //     "IdReligion": 0,
    //     "descripcionOtraReligion": ""
    // }

    return client
}


const ClienteModel = {
    getDataGenericClient
}


export default ClienteModel