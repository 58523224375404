const castFormatoMoneda = (text) => {
    try {
        if (text === undefined) {
            return "";
        } else {
            const formatoMoneda = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
                maximumFractionDigits: 2,
                minimumFractionDigits: 0
            });
            const currency = formatoMoneda.format(text);

            return currency;
        }
    } catch (error) {
        console.error(error)
        return "Error"
    }

}

const formatoNumeroTelefono = (numero) => {
    if (numero === undefined) return "";
    if (numero === "") return "";
    return numero.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2-$3-$4');

}

const NumeroTools = {
    castFormatoMoneda,
    formatoNumeroTelefono
}

export default NumeroTools;