import axios from "axios";
import API from '../config/network.config';
import swal2 from 'sweetalert2';
import colors from "../config/colors.config";
// import moment from "moment/moment";
import Cookies from "universal-cookie";
import components from "../tools/components.tool";

// import moment from "moment/moment";


const cookies = new Cookies();
const urlApi = API + "/cliente";


const mensajeErrorLocal = (error) => {

    let menssage = error.config.url + ": " + error.message === undefined ? "undefined" : error.message;

    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}

const mensajeErrorServices = (error) => {

    let menssage = error


    swal2.fire({
        icon: 'error',
        title: '¡Upss!',
        text: "No se pudo establecer conexión con el servidor",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    });
}


const mensaje403 = () => {

    let menssage = "Error 403"


    document.body.innerHTML = "<body></body>"

    swal2.fire({
        icon: 'warning',
        title: '¡Sesión caducada!',
        text: "Vuelve a iniciar sesión para poder usar este sistema",
        //timer: "5000",
        confirmButtonColor: colors.azul,
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<p style="text-align: center; color: ${colors.rojo};">${menssage}</p>`
    }).then(() => {
        window.location.href = "/login";
    });
}

const getNivelEstudios = async () => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/nivel-estudios-listar`,
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }

    return data

}

const guardarCliente = async (cliente) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "POST",
            url: `${urlApi}/guardar`,
            data: {
                cliente
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const getEstadosCiviles = async (cliente) => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/estados-civiles-listar`,
            data: {
                cliente
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const getTipoVivienda = async () => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/tipos-vivienda`,
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const getListaFormaPago = async () => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/listar-formas-pago`,
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const getListaPlazoPagoCredito = async () => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/listar-plazo-pago-credito`,
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const getListaMontoCredito = async () => {

    let response;
    let data = null;

    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/listar-montos-credito`,
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}


const validarDatosCliente = async (cliente, toast, mostrarBotonesEscogerTieneNegocioTrabajo, solicitudCredito, referencia) => {

    if (cliente.tieneNegocioCliente == 1) {
        if (cliente.descripcionLocalPropioRentado == 0) {
            toast.warning("Falto especificar si el local es tuyo o rentado");
            components.fucusElement("descripcionLocalPropioRentado")
            return false;
        }
    }



    if (cliente.nombreCliente.length === 0) {
        toast.warning("Falta que escribas tu nombre");
        components.fucusElement("nombreCliente")
        return false;
    }

    if (cliente.apellidoPaternoCliente.length === 0) {
        toast.warning("Falta que escribas tu apellido paterno");
        components.fucusElement("apellidoPaternoCliente")
        return false;
    }

    if (cliente.apellidoMaternoCliente.length === 0) {
        toast.warning("Falta que escribas tu apellido materno");
        components.fucusElement("apellidoMaternoCliente")
        return false;
    }

    if (cliente.numeroTelefonoCliente.length < 10) {
        toast.warning("El numero de teléfono debe de tener 10 dígitos");
        components.fucusElement("numeroTelefonoCliente")
        return false;
    }

    if (cliente.correoElectronicoCliente.length === 0) {
        toast.warning("Falta que escribas tu correo electrónico");
        components.fucusElement("correoElectronicoCliente")
        return false;
    } else {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(cliente.correoElectronicoCliente)) {
            toast.warning("Tu correo electrónico no es valido");
            components.fucusElement("correoElectronicoCliente")
            return false;
        }
    }

    if (cliente.IdPaisNacimientoCliente == 0) {
        toast.warning("No has seleccionado tu país de nacimiento");
        components.fucusElement("IdPaisNacimientoCliente");
        return false;
    }

    if (cliente.IdEstadoNacimientoCliente == 0) {
        toast.warning("No has seleccionado tu estado de nacimiento");
        components.fucusElement("IdPaisNacimientoCliente");
        return false;
    }

    if (cliente.IdCiudadNacimientoCliente == 0) {
        toast.warning("No has seleccionado tu ciudad de nacimiento");
        components.fucusElement("IdCiudadNacimientoCliente");
        return false;
    }

    if (cliente.IdCiudadNacimientoCliente == 0) {
        toast.warning("No has seleccionado tu ciudad de nacimiento");
        components.fucusElement("IdCiudadNacimientoCliente");
        return false;
    }

    if (cliente.claveElectorCliente.length == 0) {
        toast.warning("Falta que escribas tu clave de lector");
        components.fucusElement("claveElectorCliente");
        return false;
    }

    if (cliente.INEFolioCliente.length == 0) {
        toast.warning("Falta que escribas tu clave tu Folio del INE");
        components.fucusElement("INEFolioCliente");
        return false;
    }

    if (cliente.INENumeroCliente.length == 0) {
        toast.warning("Falta que escribas tu clave tu Numero del INE");
        components.fucusElement("INENumeroCliente");
        return false;
    }

    if (cliente.CURPCliente.length == 0) {
        toast.warning("Falta que escribas tu clave tu CURP");
        components.fucusElement("IdCiudadNacimientoCliente");
        return false;
    } else {
        let curpRegex = /^[A-Z]{4}\d{6}[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS)[A-Z]{3}[A-Z0-9]{1}\d{1}$/;

        if (!curpRegex.test(cliente.CURPCliente)) {
            toast.warning("Esta CURP no es valida");
            toast.warning("Asegúrate de haberlo escrito bien");
            components.fucusElement("IdCiudadNacimientoCliente");
            return false;
        }

    }

    if (cliente.RFCCliente.length == 0) {
        toast.warning("Falta que escribas tu clave tu RFC");
        components.fucusElement("RFCCliente");
        return false;
    }

    if (cliente.IdPaisViviendaCliente == 0) {
        toast.warning("Falta que selecciones el país donde vives");
        components.fucusElement("IdPaisViviendaCliente");
        return false;
    }

    if (cliente.IdEstadoViviendaCliente == 0) {
        toast.warning("Falta que selecciones el estado donde vives");
        components.fucusElement("IdEstadoViviendaCliente");
        return false;
    }

    if (cliente.IdCiudadViviendaClietne == 0) {
        toast.warning("Falta que selecciones el la ciudad donde vives");
        components.fucusElement("IdCiudadViviendaClietne");
        return false;
    }

    if (cliente.IdLocalidadViviendaCliente == 0) {
        toast.warning("Falta que selecciones la localidad de donde vives");
        components.fucusElement("IdLocalidadViviendaCliente")
        return false;
    }

    if (cliente.calle_y_numeroCasaCliente.length == 0) {
        toast.warning("Falta que escribas el la calle y el numero de casa");
        components.fucusElement("calle_y_numeroCasaCliente")
        return false;
    }

    if (cliente.IdTipoViviendaCliente == 0) {
        toast.warning("Falta que selecciones el tipo de vivienda");
        components.fucusElement("IdTipoViviendaCliente")
        return false;
    }

    if (cliente.nombreConyugueCliente.length == 0) {
        toast.warning("Falta que escribas el nombre del cónyuge");
        components.fucusElement("nombreConyugueCliente")
        return false;
    }

    if (cliente.dependientesEconimicosCliente.length == 0) {
        toast.warning("Falta que escribas los dependientes económicos");
        components.fucusElement("dependientesEconimicosCliente")
        return false;
    }

    if (cliente.gastosRentaCasa === "") {
        toast.warning("Falta que escribas los gastos de renta");
        components.fucusElement("gastosRentaCasa")
        return false;
    }

    if (cliente.gastosEducacionCasa === "") {
        toast.warning("Falta que escribas los gastos de educación");
        components.fucusElement("gastosEducacionCasa")
        return false;
    }

    if (cliente.gastosSaludCasa === "") {
        toast.warning("Falta que escribas los gastos de salud");
        components.fucusElement("gastosSaludCasa")
        return false;
    }

    if (cliente.gastosAguaCasa === "") {
        toast.warning("Falta que escribas los gastos de agua");
        components.fucusElement("gastosAguaCasa")
        return false;
    }

    if (cliente.gastosLuzCasa === "") {
        toast.warning("Falta que escribas los gastos de luz");
        components.fucusElement("gastosLuzCasa")
        return false;
    }

    if (cliente.gastosInternetCasa === "") {
        toast.warning("Falta que escribas los gastos de internet");
        components.fucusElement("gastosInternetCasa")
        return false;
    }

    if (cliente.gastosOtrosCasa === "") {
        toast.warning("Falta que escribas los otros gastos");
        components.fucusElement("gastosInternetCasa")
        return false;
    }

    if (cliente.fechaNacimientoCliente == "") {
        toast.warning("Falta que escribas tu fecha de nacimiento");
        components.fucusElement("fechaNacimientoCliente")
        return false;
    }

    if (mostrarBotonesEscogerTieneNegocioTrabajo) {
        toast.warning("No has especificado si eres trabajador o si tienes negocio");
        return false;
    }



    if (cliente.nombreCompletoPersonaReferencia1Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("Nombre completo de la persona 1");
        components.fucusElement("nombreCompletoPersonaReferencia1Cliente")
        return false;
    }

    if (cliente.parentescoPersonaReferencia1Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("parentesco de la persona 1");
        components.fucusElement("parentescoPersonaReferencia1Cliente")
        return false;
    }
    if (cliente.numeroTelefonoPersonaReferencia1Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("Teléfono de la persona 1");
        components.fucusElement("numeroTelefonoPersonaReferencia1Cliente")
        return false;
    }

    if (cliente.direccionReferencia1Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("Dirección de la persona 1");
        components.fucusElement("direccionReferencia1Cliente")
        return false;
    }

    /******************************************************/

    if (cliente.puestoCategoriaTrabajoCliente.length === 0) {
        toast.warning("En análisis del negocio faltaron datos por llenar");
        toast.warning("Puesto o  Categoría");
        components.fucusElement("puestoCategoriaTrabajoCliente");
        return false;
    }
    if (cliente.numeroTelefonoNegocioCliente.length === 0) {
        toast.warning("En análisis del negocio faltaron datos por llenar");
        toast.warning("Numero de teléfono");
        components.fucusElement("numeroTelefonoNegocioCliente");
        return false;
    }
    if (cliente.direccionTrabajoCliente.length === 0) {
        toast.warning("En análisis del negocio faltaron datos por llenar");
        toast.warning("Dirección");
        components.fucusElement("direccionTrabajoCliente");
        return false;
    }

    if (cliente.puntpReferenciaTrabajoCliente.length === 0) {
        toast.warning("En análisis del negocio faltaron datos por llenar");
        toast.warning("Punto de referencia");
        components.fucusElement("puntpReferenciaTrabajoCliente");
        return false;
    }

    if (cliente.puntpReferenciaTrabajoCliente.length === 0) {
        toast.warning("En análisis del negocio faltaron datos por llenar");
        toast.warning("Punto de referencia");
        components.fucusElement("puntpReferenciaTrabajoCliente");
        return false;
    }

    if (cliente.antiguedadTrabajoCliente.length === 0) {
        toast.warning("En análisis del negocio faltaron datos por llenar");
        toast.warning("Antigüedad/Tiempo con el negocio");
        components.fucusElement("antiguedadTrabajoCliente");
        return false;
    }
    if (cliente.ingresoMensualTrabajoCliente == 0) {
        toast.warning("En análisis del negocio faltaron datos por llenar");
        toast.warning("Ingreso mensual");
        components.fucusElement("ingresoMensualTrabajoCliente");
        return false;
    }











    /****************************************************/



    if (cliente.nombreCompletoPersonaReferencia2Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("Nombre completo de la persona 2");
        components.fucusElement("nombreCompletoPersonaReferencia2Cliente")
        return false;
    }

    if (cliente.parentescoPersonaReferencia2Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("parentesco de la persona 2");
        components.fucusElement("parentescoPersonaReferencia2Cliente")
        return false;
    }
    if (cliente.numeroTelefonoPersonaReferencia2Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("Teléfono de la persona 2");
        components.fucusElement("numeroTelefonoPersonaReferencia2Cliente")
        return false;
    }

    if (cliente.direccionReferencia2Cliente.length === 0) {
        toast.warning("En personas que no viven con tigo faltaron datos por llenar");
        toast.warning("Dirección de la persona 2");
        components.fucusElement("direccionReferencia2Cliente")
        return false;
    }

    if (cliente.tieneNegocioCliente === 1) {
        if (cliente.descripcionLocalPropioRentado == 0) {
            toast.warning("Falto especificar si el local es tuyo o rentado");
            components.fucusElement("descripcionLocalPropioRentado")
            return false;
        }
    }




    let {
        IdTipoSolicitudCredito,
        IdMontotoSolicitudCredito,
        IdPlazoPagoSolicitudCredito,
        IdFormaPagoSolicitudCredito,
    } = await solicitudCredito;


    if (referencia === 1) {
        return true;
    }

    if (IdTipoSolicitudCredito === 0) {
        toast.warning("Falta que especifiques el tipo de crédito");
        components.fucusElement("IdTipoSolicitudCredito")
        return false;
    }

    if (IdMontotoSolicitudCredito === 0) {
        toast.warning("Falta que especifiques el monto");
        components.fucusElement("IdMontotoSolicitudCredito")
        return false;
    }

    if (IdPlazoPagoSolicitudCredito === 0) {
        toast.warning("Falta que especifiques el plazo");
        components.fucusElement("IdPlazoPagoSolicitudCredito")
        return false;
    }

    if (IdFormaPagoSolicitudCredito === 0) {
        toast.warning("Falta que especifiques la forma de pago");
        components.fucusElement("IdFormaPagoSolicitudCredito")
        return false;
    }



    return true;
}





const getListaClientes = async () => {

    let response;
    let data = null;


    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/listar`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const getCliente = async (IdCliente) => {

    let response;
    let data = null;
    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/obtener/${IdCliente}`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data

}

const buscarCliente = async (texto) => {
    let response;
    let data = null;
    try {
        response = await axios({
            method: "POST",
            url: `${urlApi}/buscar`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
            data: {
                texto
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data
}

const getListaReligiones = async () => {
    let response;
    let data = null;
    try {
        response = await axios({
            method: "GET",
            url: `${urlApi}/religion/listar`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
            data: {}
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data
}

const getListaFormulario = async (fecha, IdStatus) => {
    let response;
    let data = null;
    console.log(`${urlApi}/listar_formulario_contacto`)
    try {
        response = await axios({
            method: "POST",
            url: `${urlApi}/listar_formulario_contacto`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
            data: {
                date: fecha,
                IdStatus,
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data
}

const eliminarFormulario = async (IdFormularioContacto) => {
    let response;
    let data = null;
    try {
        response = await axios({
            method: "DELETE",
            url: `${urlApi}/eliminar_formulario`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
            data: {
                IdFormularioContacto
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data
}

const formulario_cambiar_status = async ({
    IdFormularioContacto,
    IdStatus
}) => {
    console.log({
        IdFormularioContacto,
        IdStatus
    });
    let response;
    let data = null;
    try {
        response = await axios({
            method: "PUT",
            url: `${urlApi}/formulario_cambiar_status`,
            headers: {
                token: await cookies.get("usuario", {
                    path: "/"
                }).token
            },
            data: {
                IdFormularioContacto,
                IdStatus
            }
        });
        data = response.data;
    } catch (error) {
        mensajeErrorLocal(error);
        data = {
            error: true,
            status: 500,
            menssage: "Error en la red"
        }
    }

    if (data.status === 403) {
        mensaje403();
        return null;
    }
    if (data.status === 500) {
        mensajeErrorServices(data.message);
        return null;
    }

    return data
}

const ClienteService = {
    getNivelEstudios,
    guardarCliente,
    getEstadosCiviles,
    getTipoVivienda,
    getListaClientes,
    getCliente,
    getNivelEstudios,
    guardarCliente,
    getEstadosCiviles,
    getTipoVivienda,
    validarDatosCliente,
    getListaFormaPago,
    getListaPlazoPagoCredito,
    getListaMontoCredito,
    buscarCliente,
    getListaReligiones,
    getListaFormulario,
    eliminarFormulario,
    formulario_cambiar_status,
}

export default ClienteService;