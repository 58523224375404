import { useEffect, useState } from "react";
import ClienteModel from "../models/Cliente.model";
import NavBar from "../components/NavBar";
import { ToastContainer, toast } from 'react-toastify';
import ClienteService from "../services/Cliente.service";
import Table from "react-data-table-component";
import { IconButton } from "@material-ui/core";
import { Add, Cached, CalendarToday, Close, Edit, ListAlt, Person, Phone, PictureAsPdf, Search, WhatsApp } from "@material-ui/icons";
import ArticleIcon from '@mui/icons-material/Article';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, DropdownItem, Spinner } from "reactstrap";
import colors from "../config/colors.config";
import localidadModel from "../services/Localidad.service";
import ClientService from "../services/Cliente.service";
import QRCode from 'qrcode.react';
import imgFondo from "../assets/image/background/003.jpg"
import NumeroTools from "../tools/Numero.tool";
import timeTool from "../tools/time.tool";
import SolicitudesCredito from "../services/SolicitudesCredito.service";
import SolicitudCreditoPDF from "../components/SolicitudCreditoPDF";
import { PDFViewer } from '@react-pdf/renderer';
import components from "../tools/components.tool";
import solicitudCreditoModal from "../services/SolicitudesCredito.service";
import solicitudCreditoModel from "../services/SolicitudCredito.service";

const SClientesLista = () => {
    const [cargandoTablaClientes, setCargandoTablaClientes] = useState(false);
    const [cliente, setCliente] = useState(ClienteModel.getDataGenericClient());
    const [clientesLista, setClientesLista] = useState([]);
    const [textoReferenciaCliente, setTextoReferenciaCliente] = useState("");
    const [isOpenModalClienteEdit, setIsOpenModalClienteEdit] = useState(false);
    const [isOpenModalSCClientes, setIsOpenModalSCClientes] = useState(false);
    const [isOpenModalTelefonoClientes, setIsOpenModalTelefonoClientes] = useState(false);
    const [listaEstadosCiviles, setListaEstadosCiviles] = useState([]);
    const [listaNivelEstudios, setListaNivelEstudios] = useState([]);
    const [listaEstadosNacimiento, setListaEstadosNacimiento] = useState([]);
    const [listaCiudadesNacimiento, setListaCiudadesNacimiento] = useState([]);
    const [listaEstadosVivienda, setListaEstadosVivienda] = useState([]);
    const [listaCiudadesVivienda, setListaCiudadesVivienda] = useState([]);
    const [listaLocalidadVivienda, setListaLocalidadVivienda] = useState([]);
    const [listaSolicitudesCredito, setListaSolicitudesCredito] = useState([]);
    const [solicitudCredito, setSolicitudCredito] = useState({});
    const [isOpenModalPDF, setIsOpenModalPDF] = useState(false);
    const [disabledButtonGuardarSC, setDisabledButtonGuardarSC] = useState(false);
    const [isOpenModalEditSC, setIsOpenModalEditSC] = useState(false);
    const [isOpenModalNuevaSC, setIsOpenModalNuevaSC] = useState(false);
    const [listaMontoPago, setListaMontoPago] = useState([]);
    const [listaPlazoPagoCredito, setListaPlazoPagoCredito] = useState([]);
    const [listaFormasPago, setListaFormasPago] = useState([]);
    const [listaTipoVivienda, setListaTipoVivienda] = useState([]);
    const [listaReligiones, setListaReligiones] = useState([]);
    const [isOpenModalEscogerTrabajoNegocio, setIsOpenModalEscogerTrabajoNegocio] = useState(false);

    const columnasTablaClientes = [
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Nombre</p>,
            selector: row => <label style={{ textDecoration: "none" }} title={concatenarNombreCompleto(row)} >{concatenarNombreCompleto(row)}</label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Lugar donde vive</p>,
            selector: row => <label style={{ textDecoration: "none" }} title={concatenarLugarDondeVive(row)} >{concatenarLugarDondeVive(row)}</label>,

            width: "400px",
        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>CURP</p>,
            selector: row => <label style={{ textDecoration: "none" }} title={row.CURPCliente} >{row.CURPCliente}</label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>RFC</p>,
            selector: row => <label style={{ textDecoration: "none" }} title={row.RFCCliente} >{row.RFCCliente}</label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Correo</p>,
            selector: row => <label
                style={{ textDecoration: "none", fontWeight: "bold", color: colors.azul_link, cursor: "pointer" }}
                title={row.correoElectronicoCliente}
                target="_blank"
                onClick={async (e) => {
                    await navigator.clipboard.writeText(row.correoElectronicoCliente + "");
                    await window.open("https://tzedaka.com.mx:2096/", '_blank');
                }}
            // href={`https://tzedaka.com.mx:2096/`}
            >
                {row.correoElectronicoCliente}
            </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Teléfono</p>,
            selector: row =>
                <label
                    style={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        color: colors.azul_link,
                        cursor: "pointer"
                    }}
                    title={NumeroTools.formatoNumeroTelefono(row.numeroTelefonoCliente)}
                    onClick={() => {

                        let enlaceTel = 'tel:+52' + row.numeroTelefonoCliente;
                        try {
                            window.location.href = enlaceTel;

                        } catch (error) {

                        }

                    }}
                >
                    {NumeroTools.formatoNumeroTelefono(row.numeroTelefonoCliente)}
                </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}></p>,
            selector: row =>
                <>
                    <IconButton
                        onClick={async () => {
                            let response = await ClienteService.getCliente(row.IdCliente);
                            let { cliente } = response;
                            response = await localidadModel.getEstadosPorPais(cliente.IdPaisNacimientoCliente);
                            let { listaEstados } = response;
                            response = await localidadModel.getCiudadesPorEstados(cliente.IdEstadoNacimientoCliente);
                            let { listaCiudades } = response;
                            response = await ClientService.getNivelEstudios();
                            let { listaNivelEstudios } = response;
                            response = await ClientService.getEstadosCiviles();
                            let { listaEstadosCiviles } = response;
                            response = await localidadModel.getEstadosPorPais(cliente.IdPaisViviendaCliente);
                            listaEstados = response.listaEstados;
                            response = await localidadModel.getCiudadesPorEstados(cliente.IdEstadoViviendaCliente);
                            listaCiudades = response.listaCiudades;
                            response = await ClienteService.getTipoVivienda();
                            let { listaTipoVivienda } = response;
                            response = await ClienteService.getListaReligiones();
                            let { listaReligiones } = response;
                            setListaReligiones(listaReligiones);
                            setListaTipoVivienda(listaTipoVivienda);
                            setListaCiudadesVivienda(listaCiudades);
                            setListaEstadosVivienda(listaEstados);
                            setListaEstadosNacimiento(listaEstados);
                            setListaCiudadesNacimiento(listaCiudades);
                            setListaNivelEstudios(listaNivelEstudios);
                            setListaEstadosCiviles(listaEstadosCiviles);
                            setCliente(cliente);
                            setIsOpenModalClienteEdit(true);
                        }}
                    >
                        <Person style={{ color: colors.verde_1 }} />
                    </IconButton>
                </>
        },
    ];

    const columnsTablaSolicitudesCredito = [
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Fecha y Hora</p>,
            selector: row => <label
                title={"Creado el " + timeTool.castFechaNacimientoEscrita(row.fechaCreacionSolicitudCredito) + " " + timeTool.formato12Horas(row.horaCreacionSolicitudCredito)}
            >
                {timeTool.castFechaNacimiento(row.fechaCreacionSolicitudCredito) + " " + timeTool.formato12Horas(row.horaCreacionSolicitudCredito)}
            </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Tipo Credito</p>,
            selector: row => <label>
                {row.IdTipoSolicitudCredito == 1 ? "Individual" : row.IdTipoSolicitudCredito == 2 ? "Grupal" : "########"}
            </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Monto Solicitado</p>,
            selector: row => <label>
                {NumeroTools.castFormatoMoneda(row.monto)}
            </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Plazo</p>,
            selector: row => <label>
                {row.plazoSolicitudCredito}
            </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Forma de Pago</p>,
            selector: row => <label>
                {row.formaPagoSolicitudCredito}
            </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}>Estatus de La Solicitud</p>,
            selector: row => <label>
                {row.statusSolicitudCredito}
            </label>,

        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 16, color: colors.azul }}></p>,
            selector: row =>
                <>
                    <IconButton
                        onClick={() => {
                            // listarHistorialSolicitudCredito(row.IdSolicitudCredito);
                            // setState({
                            //     estaAbiertoModalHistorialSolicitudCredito: true,
                            //     solicitudCredito: row,
                            // });
                        }}
                        title="Ver historial de la Solicitud de Credito"
                        style={{
                            backgroundColor: colors.blanco,
                            color: colors.verde_1,
                        }}
                    >
                        <ListAlt />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            cargarDatosSC();
                            setSolicitudCredito(row);
                            setIsOpenModalEditSC(true);
                        }}
                        style={{
                            backgroundColor: colors.blanco,
                            color: colors.verde_1,
                        }}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={async () => {
                            setSolicitudCredito(row);
                            setIsOpenModalPDF(true);
                        }}
                        title={"Generar PDF"}
                    >
                        <PictureAsPdf style={{ color: colors.rojoPDF }} />
                    </IconButton>
                </>,

        },


    ];

    const esTrabajadorTieneNegocio = (esTrabajadorCliente, tieneNegocioCliente) => {
        // console.log({ esTrabajadorCliente, tieneNegocioCliente })
        let value = "Especifica si es Trabajador o si Tiene Negocio";
        if (esTrabajadorCliente === 1 && tieneNegocioCliente === 0) {
            value = "Es trabajador"
        }
        if (esTrabajadorCliente === 0 && tieneNegocioCliente === 1) {
            value = "Tiene Negocio"
        }

        return value
    }

    useEffect(() => {
        document.body.style = `background: url(${imgFondo}); background-repeat: no-repeat; background-size: cover;`
        getListaClientes();
    }, []);

    const cargarDatosSC = async () => {
        let response = await ClienteService.getListaFormaPago();
        let { listaFormasPago } = response;
        response = await ClienteService.getListaPlazoPagoCredito();
        let { listaPlazoPagoCredito } = response;
        response = await ClienteService.getListaMontoCredito();
        let { listaMontoPago } = response;

        setListaFormasPago(listaFormasPago);
        setListaPlazoPagoCredito(listaPlazoPagoCredito);
        setListaMontoPago(listaMontoPago);

    }

    const getListaClientes = async () => {
        setCargandoTablaClientes(true);
        let response = await ClienteService.getListaClientes();
        let { listaClientes } = response;
        setClientesLista(listaClientes);
        setCargandoTablaClientes(false);
    }

    const getListarSolicitudesCredito = async () => {
        let response = await SolicitudesCredito.getSolicitudesCreditoPorIdCliente(cliente.IdCliente);
        let { ListaSolicitudesCredito } = response
        setListaSolicitudesCredito(ListaSolicitudesCredito);
    }

    const buscarCliente = async () => {
        if (textoReferenciaCliente.length === 0) {
            toast.warn("No has especificado el nombre, apellidos del cliente a buscar");
            // components.fucusElement("textoReferenciaCliente");
            return
        }
        let response = await ClienteService.buscarCliente(textoReferenciaCliente);
        let { status, listaClientes } = response;
        if (status === 200) {
            toast.info(`${listaClientes.length} clientes encontrados`);
            setClientesLista(listaClientes)
        } else {
            toast.error("Error al conectar con el servidor");
        }

    }

    const obtenerCliente = async (IdCliente) => {
        let response = await ClienteService.getCliente(IdCliente);
        let { cliente } = response;
        setCliente(cliente);
    }

    const guardarCliente = async () => {

        // let value = await ClientService.validarDatosCliente(cliente, toast, mostrarBotonesEscogerTieneNegocioTrabajo, {},1);

        // if (value) {
        toast.info("Datos guardados");
        let response = await ClientService.guardarCliente(cliente);
        console.log(response)
        let { IdCliente } = response;

        await setCliente({
            ...cliente,
            IdCliente,
        });
        // }

    }

    const cambiarDatosCliente = async (event) => {
        let { name, value } = event.target;
        await setCliente({
            ...cliente,
            [name]: value,
        });
    }

    const concatenarNombreCompleto = (row) => {
        return row.nombreCliente + " " + row.apellidoPaternoCliente + " " + row.apellidoMaternoCliente
    }

    const concatenarLugarDondeVive = (row) => {
        return row.calle_y_numeroCasaCliente + ", " + row.nombreLocalidadVivienda + ", " + row.nombreCiudadVivienda + ", " + row.nombreEstadoVivienda + ", " + row.nombrePaisVivienda
    }

    const cambioSolicitudCredito = async ({ target }) => {
        let { name, value } = target;
        setSolicitudCredito({
            ...solicitudCredito,
            [name]: value,
        });
    }

    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <section style={{ width: "100%", height: "100vh" }} >
                <NavBar />
                <div style={{ height: 80 }} ></div>
                <div
                    style={{
                        marginRight: 30,
                        marginLeft: 30,
                        padding: 5,
                        backgroundColor: colors.blanco,
                        borderRadius: 12,
                    }}
                >
                    <Row style={{ padding: 5 }} >
                        <Col style={{ width: "50%", minWidth: "50%", maxWidth: "50%" }} >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        color: colors.azul,
                                    }}
                                >
                                    Buscar Cliente
                                </Label>
                                <InputGroup>
                                    <Input
                                        value={textoReferenciaCliente}
                                        placeholder="Buscar por nombre, apellido paterno o materno"
                                        onChange={({ target }) => {
                                            let { value } = target;
                                            value = value.replace(/\s+/g, ' ');
                                            value = value.replace(/[^a-zA-Z0-9\s]/g, '');
                                            setTextoReferenciaCliente(value);
                                        }}
                                        onKeyDown={({ key }) => {
                                            if (key === "Enter") {
                                                buscarCliente();
                                            }
                                        }}
                                    />
                                    <InputGroupText
                                        style={{
                                            backgroundColor: colors.azul,
                                            color: colors.blanco,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            buscarCliente();
                                        }}
                                    >
                                        <Search />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col style={{ width: "50%", minWidth: "50%", maxWidth: "50%", textAlign: "right" }} >
                            <IconButton
                                style={{
                                    backgroundColor: "#F2F2F2",
                                    color: colors.azul,
                                    marginTop: 30,
                                    marginRight: 15
                                }}
                                onClick={async () => {
                                    await getListaClientes();
                                    await toast.info("Tabla actualizada");
                                }}
                            >
                                {cargandoTablaClientes ? <Spinner type="grow" style={{ height: 30, width: 30,fontWeight:"normal" }} /> : <Cached />}
                            </IconButton>
                        </Col>
                    </Row>
                    <Row
                        style={{ overflowY: "scroll", height: "68vh", margin: 0, }}
                    >
                        {
                            cargandoTablaClientes ?
                                <center>
                                    <Label style={{ marginTop: "25vh", fontWeight: "bold", fontSize: 34, color: colors.azul }} >
                                        Cargando Tabla
                                    </Label>
                                </center>
                                :
                                <Table
                                    columns={columnasTablaClientes}
                                    data={clientesLista}
                                    // title={<label style={{ color: colors.verde_1, fontWeight: "bold" }} >{`Hay ${listaClientes.length} clientes encontrados`}   </label>}
                                    noDataComponent={<p style={{ fontWeight: "bold" }}>No hay clientes registrados</p>}
                                    // pagination
                                    dense
                                />
                        }
                        <div style={{ height: "10vh" }} ></div>
                    </Row>

                </div>
            </section >
            <Modal
                isOpen={isOpenModalClienteEdit}
                fullscreen
            >
                <ModalHeader
                    style={{
                        backgroundColor: colors.azul
                    }}
                    close={
                        <Row>
                            <Col>
                                {
                                    cliente.IdCliente.length > 0 &&
                                    <>
                                        <IconButton
                                            style={{
                                                backgroundColor: "#F2F2F2", color: colors.azul, marginRight: "7px",
                                            }}
                                            onClick={async () => {
                                                // https://wa.me/whatsappphonetext/?text=urlencodedtext
                                                await window.open(`https://wa.me/+52${cliente.numeroTelefonoCliente}/?text=urlencodedtext`, '_blank');
                                            }}
                                        >
                                            <WhatsApp />
                                        </IconButton>
                                        <IconButton
                                            style={{
                                                marginRight: "7px",
                                                backgroundColor: "#F2F2F2", color: colors.azul
                                            }}
                                            onClick={() => {
                                                setIsOpenModalTelefonoClientes(true);
                                            }}
                                        >
                                            <Phone />
                                        </IconButton>

                                        <IconButton
                                            style={{
                                                marginRight: 5,
                                                marginLeft: 5,
                                                backgroundColor: "#F2F2F2", color: colors.azul
                                            }}
                                            onClick={async () => {
                                                // setIsOpenModalClienteEdit(false);
                                                setIsOpenModalSCClientes(true);
                                                getListarSolicitudesCredito();
                                            }}
                                            title="Ver solicitudes de crédito"
                                        >
                                            <ArticleIcon />
                                        </IconButton>
                                        <IconButton
                                            style={{
                                                marginRight: 5,
                                                marginLeft: 5,
                                                backgroundColor: "#F2F2F2", color: colors.azul
                                            }}
                                            onClick={async () => {
                                                await obtenerCliente(cliente.IdCliente);
                                            }}
                                            title="Volver a cargar los datos del cliente"
                                        >
                                            <Cached />
                                        </IconButton>
                                    </>
                                }

                                <IconButton
                                    style={{
                                        marginRight: 5,
                                        marginLeft: 5,
                                        backgroundColor: "#F2F2F2", color: colors.azul
                                    }}
                                    onClick={() => {
                                        setIsOpenModalClienteEdit(false);
                                    }}
                                    title="Cerrar ventana"
                                >
                                    <Close />
                                </IconButton>
                            </Col>
                        </Row>
                    }
                >
                    <Label style={{ fontSize: 24, color: colors.blanco, fontWeight: "bold" }} >
                        Datos del Cliente
                    </Label>
                </ModalHeader>
                <ModalBody style={{}} >
                    {/* Datos Generales */}
                    <Row >
                        <Col>
                            <Label style={{ fontWeight: "bold", fontSize: 24, color: colors.azul }}>
                                Datos Generales
                            </Label>
                        </Col>
                    </Row>
                    <Row  >
                        <Col
                        // // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Nombre(s)
                                </Label>
                                <Input
                                    id="nombreCliente"
                                    name="nombreCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.nombreCliente}
                                    onChange={(event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Apellido Paterno
                                </Label>
                                <Input
                                    id="apellidoPaternoCliente"
                                    name="apellidoPaternoCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.apellidoPaternoCliente}
                                    onChange={(event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Apellido Materno
                                </Label>
                                <Input
                                    id="apellidoMaternoCliente"
                                    name="apellidoMaternoCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.apellidoMaternoCliente}
                                    onChange={(event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Numero Teléfono
                                </Label>
                                <Input
                                    id="numeroTelefonoCliente"
                                    name="numeroTelefonoCliente"
                                    placeholder="Solo números"
                                    type="text"
                                    value={NumeroTools.formatoNumeroTelefono(cliente.numeroTelefonoCliente)}
                                    onChange={(event) => {
                                        let { value } = event.target;
                                        value = value.replace(/-/g, '');
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            cambiarDatosCliente(event);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Correo Electrónico
                                </Label>
                                <Input
                                    id="correoElectronicoCliente"
                                    name="correoElectronicoCliente"
                                    placeholder="Cuenta de Correo electrónico"
                                    value={cliente.correoElectronicoCliente}
                                    onChange={(event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    País de Nacimiento
                                </Label>
                                <Input
                                    id="IdPaisNacimientoCliente"
                                    name="IdPaisNacimientoCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdPaisNacimientoCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                        // let response = await localidadModel.getEstadosPorPais(event.target.value)
                                        // let { listaEstados } = response;

                                        // if (event.target.value == 0) {
                                        //     // await setState({
                                        //     //     cliente: {
                                        //     //         ...cliente,
                                        //     //         IdCiudadNacimientoCliente: 0,
                                        //     //         IdEstadoNacimientoCliente: 0,
                                        //     //     }
                                        //     // });
                                        // } else {
                                        //     // await setState({
                                        //     //     listaEstadosNacimiento: listaEstados,
                                        //     // });
                                        // }
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    <option value={1}>México</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Estado de Nacimiento
                                </Label>
                                <Input
                                    id="IdEstadoNacimientoCliente"
                                    name="IdEstadoNacimientoCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdEstadoNacimientoCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                        let response = await localidadModel.getCiudadesPorEstados(event.target.value);
                                        let { listaCiudades } = response;
                                        setListaCiudadesNacimiento(listaCiudades);

                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaEstadosNacimiento.map((estado) => {
                                            return (
                                                <option value={estado.IdEstado}>{estado.nombreEstado}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Ciudad de Nacimiento
                                </Label>
                                <Input
                                    id="IdCiudadNacimientoCliente"
                                    name="IdCiudadNacimientoCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdCiudadNacimientoCliente}
                                    onChange={(event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaCiudadesNacimiento.map((ciudad) => {
                                            return (
                                                <option value={ciudad.IdCiudad}>{ciudad.nombreCiudad}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Nivel de Estudio
                                </Label>
                                <Input
                                    id="IdNivelEstudioCliente"
                                    name="IdNivelEstudioCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdNivelEstudioCliente}
                                    onChange={(event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaNivelEstudios.map((nivelEstudio) => {
                                            return (
                                                <option value={nivelEstudio.IdEstudio}>{nivelEstudio.descripcion}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Estado Civil
                                </Label>
                                <Input
                                    id="IdEstadoCivilCliente"
                                    name="IdEstadoCivilCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdEstadoCivilCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaEstadosCiviles.map((estadoCivil) => {
                                            return (
                                                <option value={estadoCivil.IdEstadoCivil}>{estadoCivil.nombreEstadoCivil}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Clave de Elector
                                </Label>
                                <Input
                                    id="claveElectorCliente"
                                    name="claveElectorCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.claveElectorCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    INE Folio
                                </Label>
                                <Input
                                    id="INEFolioCliente"
                                    name="INEFolioCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.INEFolioCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    INE Numero
                                </Label>
                                <Input
                                    id="INENumeroCliente"
                                    name="INENumeroCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.INENumeroCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    CURP
                                </Label>
                                <Input
                                    id="CURPCliente"
                                    name="CURPCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.CURPCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    RFC
                                </Label>
                                <Input
                                    id="RFCCliente"
                                    name="RFCCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.RFCCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                    //style={style.rows.center}
                    >
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    País Donde Vives
                                </Label>
                                <Input
                                    id="IdPaisViviendaCliente"
                                    name="IdPaisViviendaCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdPaisViviendaCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                        let response = await localidadModel.getEstadosPorPais(event.target.value)
                                        let { listaEstados } = response;
                                        setListaEstadosVivienda(listaEstados);
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    <option value={1}>México</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Estado Donde Vives
                                </Label>
                                <Input
                                    id="IdEstadoViviendaCliente"
                                    name="IdEstadoViviendaCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdEstadoViviendaCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                        let response = await localidadModel.getCiudadesPorEstados(event.target.value);
                                        let { listaCiudades } = response;
                                        setListaCiudadesVivienda(listaCiudades);
                                        // await setState({
                                        //     listaCiudadesVivienda: listaCiudades
                                        // });
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaEstadosVivienda.map((estado) => {
                                            return (
                                                <option value={estado.IdEstado}>{estado.nombreEstado}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Ciudad Donde Vives
                                </Label>
                                <Input
                                    id="IdCiudadViviendaCliente"
                                    name="IdCiudadViviendaCliente"
                                    placeholder="Texto libre"
                                    type="select"
                                    value={cliente.IdCiudadViviendaCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                        let response = await localidadModel.getLocaldiadPorCiudad(event.target.value);
                                        let { listaLocalidad } = response;
                                        setListaLocalidadVivienda(listaLocalidad);
                                        // await setState({
                                        //     listaLocalidadVivienda: listaLocalidad
                                        // });

                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaCiudadesVivienda.map((ciudad) => {
                                            return (
                                                <option value={ciudad.IdCiudad}>{ciudad.nombreCiudad}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Localidad Donde Vives
                                </Label>
                                <Input
                                    id="IdLocalidadViviendaCliente"
                                    name="IdLocalidadViviendaCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdLocalidadViviendaCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);

                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaLocalidadVivienda.map((localidad) => {
                                            return (
                                                <option value={localidad.IdLocalidad}>{localidad.nombreLocalidad}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                        //////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Calle y Numero de Casa
                                </Label>
                                <Input
                                    id="calle_y_numeroCasaCliente"
                                    name="calle_y_numeroCasaCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.calle_y_numeroCasaCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col
                            // ////  md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Tipo de Vivienda
                                </Label>
                                <Input
                                    id="IdTipoViviendaCliente"
                                    name="IdTipoViviendaCliente"
                                    placeholder=""
                                    type="select"
                                    value={cliente.IdTipoViviendaCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaTipoVivienda.map((tipoVivienda) => {
                                            return (
                                                <option value={tipoVivienda.IdTipoVivienda}>{tipoVivienda.descripcionTipoVivienda}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col
                            // ////  md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Nombre del Cónyuge
                                </Label>
                                <Input
                                    id="nombreConyugueCliente"
                                    name="nombreConyugueCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.nombreConyugueCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Dependientes Económicos
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="dependientesEconimicosCliente"
                                    name="dependientesEconimicosCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.dependientesEconimicosCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Gastos de Renta
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosRentaCasa"
                                    name="gastosRentaCasa"
                                    placeholder="0.00"
                                    type="text"
                                    value={NumeroTools.castFormatoMoneda(cliente.gastosRentaCasa)}
                                    onChange={async (event) => {
                                        const name = "gastosRentaCasa";
                                        let { value } = event.target;
                                        value = value.replace(/[^\d]/g, '');
                                        const sEvent = {
                                            target: {
                                                name,
                                                value
                                            }
                                        }
                                        cambiarDatosCliente(sEvent);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Gastos de Educación
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosEducacionCasa"
                                    name="gastosEducacionCasa"
                                    placeholder="0.00"
                                    type="text"
                                    value={NumeroTools.castFormatoMoneda(cliente.gastosEducacionCasa)}
                                    onChange={async (event) => {
                                        const name = "gastosEducacionCasa";
                                        let { value } = event.target;
                                        value = value.replace(/[^\d]/g, '');
                                        const sEvent = {
                                            target: {
                                                name,
                                                value
                                            }
                                        }
                                        cambiarDatosCliente(sEvent);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Gastos de Salud
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosSaludCasa"
                                    name="gastosSaludCasa"
                                    placeholder="0.00"
                                    type="text"
                                    value={NumeroTools.castFormatoMoneda(cliente.gastosSaludCasa)}
                                    onChange={async (event) => {
                                        const name = "gastosSaludCasa";
                                        let { value } = event.target;
                                        value = value.replace(/[^\d]/g, '');
                                        const sEvent = {
                                            target: {
                                                name,
                                                value
                                            }
                                        }
                                        cambiarDatosCliente(sEvent);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Gastos de Agua
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosAguaCasa"
                                    name="gastosAguaCasa"
                                    placeholder="0.00"
                                    type="text"
                                    value={NumeroTools.castFormatoMoneda(cliente.gastosAguaCasa)}
                                    onChange={async (event) => {
                                        const name = "gastosAguaCasa";
                                        let { value } = event.target;
                                        value = value.replace(/[^\d]/g, '');
                                        const sEvent = {
                                            target: {
                                                name,
                                                value
                                            }
                                        }
                                        cambiarDatosCliente(sEvent);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Gastos de Luz
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="gastosLuzCasa"
                                    name="gastosLuzCasa"
                                    placeholder="0.00"
                                    type="text"
                                    value={NumeroTools.castFormatoMoneda(cliente.gastosLuzCasa)}
                                    onChange={async (event) => {
                                        const name = "gastosLuzCasa";
                                        let { value } = event.target;
                                        value = value.replace(/[^\d]/g, '');
                                        const sEvent = {
                                            target: {
                                                name,
                                                value
                                            }
                                        }
                                        cambiarDatosCliente(sEvent);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>

                        <Col
                            // md={"" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Gastos de Internet
                                </Label>
                                <InputGroup>
                                    <InputGroupText>
                                        $
                                    </InputGroupText>
                                    <Input
                                        style={{
                                            // width : "900px",
                                        }}
                                        id="gastosInternetCasa"
                                        name="gastosInternetCasa"
                                        placeholder="0.00"
                                        type="number"
                                        value={cliente.gastosInternetCasa}
                                        onChange={async (event) => {
                                            let { value } = event.target;
                                            if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                cambiarDatosCliente(event);
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            // md={"" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Otros Gastos
                                </Label>
                                <InputGroup>
                                    <InputGroupText>
                                        $
                                    </InputGroupText>
                                    <Input
                                        style={{
                                            // width : "900px",
                                        }}
                                        id="gastosOtrosCasa"
                                        name="gastosOtrosCasa"
                                        placeholder="0.00"
                                        type="number"
                                        value={cliente.gastosOtrosCasa}
                                        onChange={async (event) => {
                                            let { value } = event.target;
                                            if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                                cambiarDatosCliente(event);
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            // md={"" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Fecha de Nacimiento ({timeTool.calcularEdad(cliente.fechaNacimientoCliente == undefined ? "" : cliente.fechaNacimientoCliente)})
                                </Label>
                                <InputGroup>
                                    <InputGroupText>
                                        <CalendarToday style={{ color: colors.azul }} />
                                    </InputGroupText>
                                    <Input
                                        style={{
                                            // width : "900px",
                                        }}
                                        id="fechaNacimientoCliente"
                                        name="fechaNacimientoCliente"
                                        type="date"
                                        value={cliente.fechaNacimientoCliente.substring(0, 10)}
                                        onChange={async (event) => {
                                            cambiarDatosCliente(event);
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            // md={"" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Religion que Profesa
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="IdReligion"
                                    name="IdReligion"
                                    type="select"
                                    value={cliente.IdReligion}
                                    onChange={async (event) => {
                                        if (event.target.value == "12") {
                                            components.sleep(0.3).then(() => {
                                                components.fucusElement("descripcionOtraReligion");
                                            });
                                        }
                                        cambiarDatosCliente(event);
                                    }}
                                >
                                    <option value={0}>Escoge una opción</option>
                                    {
                                        listaReligiones.map((religion) => {
                                            return (
                                                <option value={religion.IdReligion}>{religion.religion}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        {
                            cliente.IdReligion == "12" ?
                                <Col
                                    // md={"" : "2"}
                                    style={{
                                        maxWidth: "20%"
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Especifica que religion profesas
                                        </Label>
                                        <Input
                                            id="descripcionOtraReligion"
                                            name="descripcionOtraReligion"
                                            placeholder=""
                                            type="text"
                                            value={cliente.descripcionOtraReligion}
                                            onChange={async (event) => {
                                                if (event.target.value.length > 100) return;
                                                cambiarDatosCliente(event);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                :
                                null
                        }
                    </Row>
                    <Row >
                        <DropdownItem divider />
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                style={{
                                    fontWeight: "bold",
                                    backgroundColor: colors.verde_1,
                                    borderColor: colors.verde_1,
                                    boxShadow: "none"
                                }}
                                onClick={() => {
                                    let { esTrabajadorCliente, tieneNegocioCliente } = cliente;
                                    console.log({ esTrabajadorCliente, tieneNegocioCliente })
                                    if (esTrabajadorCliente === 0 && tieneNegocioCliente === 0) {
                                        setIsOpenModalEscogerTrabajoNegocio(true)
                                    } else {
                                        if (esTrabajadorCliente === 1) {
                                            esTrabajadorCliente = 0
                                            tieneNegocioCliente = 1
                                        } else {
                                            if (tieneNegocioCliente === 1) {
                                                esTrabajadorCliente = 1
                                                tieneNegocioCliente = 0
                                            }
                                        }
                                        setCliente({
                                            ...cliente,
                                            esTrabajadorCliente,
                                            tieneNegocioCliente,
                                        });
                                    }



                                }}
                                title={`Has click para cambiar su perfil a ${cliente.esTrabajadorCliente === 1 && "Tiene negocio"} ${cliente.tieneNegocioCliente === 1 && "Trabajador"}`.replace("false", "")}
                            >
                                {esTrabajadorTieneNegocio(cliente.esTrabajadorCliente, cliente.tieneNegocioCliente)}
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }} >
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Puesto/Categoría
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="puestoCategoriaTrabajoCliente"
                                    name="puestoCategoriaTrabajoCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.puestoCategoriaTrabajoCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Teléfono
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="numeroTelefonoNegocioCliente"
                                    name="numeroTelefonoNegocioCliente"
                                    placeholder="Solo números"
                                    type="text"
                                    value={NumeroTools.formatoNumeroTelefono(cliente.numeroTelefonoNegocioCliente)}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        value = value.replace(/-/g, '');
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            cambiarDatosCliente(event);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Dirección
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="direccionTrabajoCliente"
                                    name="direccionTrabajoCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.direccionTrabajoCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Punto de Referencia
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="puntpReferenciaTrabajoCliente"
                                    name="puntpReferenciaTrabajoCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.puntpReferenciaTrabajoCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                        // ////  md={ "" : "2"}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    {cliente.esTrabajadorCliente === 1 ? "Tiempo con tu Negocio" : "Antigüedad en tu Trabajo"}
                                </Label>
                                <Input
                                    style={{}}
                                    id="antiguedadTrabajoCliente"
                                    name="antiguedadTrabajoCliente"
                                    placeholder="Texto libre"
                                    type="text"
                                    value={cliente.antiguedadTrabajoCliente}
                                    onChange={async (event) => {
                                        cambiarDatosCliente(event);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col
                            ////  md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Ingreso Mensual
                                </Label>
                                <Input
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="ingresoMensualTrabajoCliente"
                                    name="ingresoMensualTrabajoCliente"
                                    placeholder=""
                                    type="text"
                                    value={NumeroTools.castFormatoMoneda(cliente.ingresoMensualTrabajoCliente)}
                                    onChange={async (event) => {
                                        const name = "ingresoMensualTrabajoCliente";
                                        let { value } = event.target;
                                        value = value.replace(/[^\d]/g, '');
                                        const sEvent = {
                                            target: {
                                                name,
                                                value
                                            }
                                        }
                                        cambiarDatosCliente(sEvent);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            ////  md={ "" : "2"}
                            style={{}}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Que Dias Trabajas
                                </Label>
                                <br />
                                <Input
                                    checked={cliente.trabajaLunesNegocioCliente === 1 ? true : false}
                                    style={{
                                        marginRight: "5px"
                                    }}
                                    id="cuantoTieneGananciaNegocio"
                                    name="cuantoTieneGananciaNegocio"
                                    placeholder=""
                                    type="checkbox"
                                    onChange={async () => {
                                        setCliente({
                                            ...cliente,
                                            trabajaLunesNegocioCliente: cliente.trabajaLunesNegocioCliente === 1 ? 0 : 1
                                        });
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        color: colors.azul,
                                    }}
                                >
                                    Lunes
                                </Label>
                                <Input
                                    checked={cliente.trabajaMartesNegocioCliente === 1 ? true : false}
                                    style={{
                                        // width : "900px",
                                        marginRight: "5px",
                                        marginLeft: "7px"
                                    }}
                                    id="cuantoTieneGananciaNegocio"
                                    name="cuantoTieneGananciaNegocio"
                                    placeholder=""
                                    type="checkbox"
                                    // value={cliente.cuantoTieneGananciaNegocio}
                                    onChange={async () => {
                                        setCliente({
                                            ...cliente,
                                            trabajaMartesNegocioCliente: cliente.trabajaMartesNegocioCliente === 1 ? 0 : 1
                                        });
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        color: colors.azul,
                                    }}
                                >
                                    Martes
                                </Label>
                                <Input
                                    checked={cliente.trabajaMiercolesNegocioCliente === 1 ? true : false}
                                    style={{
                                        marginRight: "5px",
                                        marginLeft: "6px"
                                    }}
                                    id="cuantoTieneGananciaNegocio"
                                    name="cuantoTieneGananciaNegocio"
                                    placeholder=""
                                    type="checkbox"
                                    onChange={async () => {
                                        setCliente({
                                            ...cliente,
                                            trabajaMiercolesNegocioCliente: cliente.trabajaMiercolesNegocioCliente === 1 ? 0 : 1
                                        });
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        color: colors.azul,
                                    }}
                                >
                                    Miércoles
                                </Label>
                                <br />
                                <Input
                                    checked={cliente.trabajaJuevesNegocioCliente === 1 ? true : false}
                                    style={{
                                        marginRight: "5px"
                                    }}
                                    id="cuantoTieneGananciaNegocio"
                                    name="cuantoTieneGananciaNegocio"
                                    placeholder=""
                                    type="checkbox"
                                    onChange={async () => {
                                        setCliente({
                                            ...cliente,
                                            trabajaJuevesNegocioCliente: cliente.trabajaJuevesNegocioCliente === 1 ? 0 : 1
                                        });
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "5px",
                                        color: colors.azul,
                                    }}
                                >
                                    Jueves
                                </Label>
                                <Input
                                    checked={cliente.trabajaViernesNegocioCliente === 1 ? true : false}
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px"
                                    }}
                                    id="cuantoTieneGananciaNegocio"
                                    name="cuantoTieneGananciaNegocio"
                                    placeholder=""
                                    type="checkbox"
                                    onChange={async () => {
                                        setCliente({
                                            ...cliente,
                                            trabajaViernesNegocioCliente: cliente.trabajaViernesNegocioCliente === 1 ? 0 : 1
                                        });
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        color: colors.azul,
                                    }}
                                >
                                    Viernes
                                </Label>
                                <Input
                                    checked={cliente.trabajaSabadoNegocioCliente === 1 ? true : false}

                                    style={{
                                        // width : "900px",
                                    }}
                                    id="cuantoTieneGananciaNegocio"
                                    name="cuantoTieneGananciaNegocio"
                                    placeholder=""
                                    type="checkbox"
                                    onChange={async () => {
                                        setCliente({
                                            ...cliente,
                                            trabajaSabadoNegocioCliente: cliente.trabajaSabadoNegocioCliente === 1 ? 0 : 1
                                        });
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        color: colors.azul,
                                    }}
                                >
                                    Sábado
                                </Label>
                                <br />
                                <Input
                                    checked={cliente.trabajaDomingoNegocioCliente === 1 ? true : false}
                                    style={{
                                        // width : "900px",
                                    }}
                                    id="cuantoTieneGananciaNegocio"
                                    name="cuantoTieneGananciaNegocio"
                                    placeholder=""
                                    type="checkbox"
                                    onChange={async () => {
                                        setCliente({
                                            ...cliente,
                                            trabajaDomingoNegocioCliente: cliente.trabajaDomingoNegocioCliente === 1 ? 0 : 1
                                        });
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "5px",
                                        color: colors.azul,
                                    }}
                                >
                                    Domingo
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col
                            ////  md={ "" : "2"}
                            style={{
                                // maxWidth:  "40%" 
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    de Trabajo (Entrada Salida)
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            marginRight: "2.5px"
                                        }}
                                        id="horaEntradaTrabajoNegocio"
                                        name="horaEntradaTrabajoNegocio"
                                        placeholder=""
                                        type="time"
                                        value={cliente.horaEntradaTrabajoNegocio}
                                        onChange={async (event) => {
                                            cambiarDatosCliente(event);
                                        }}
                                    />
                                    <Input
                                        style={{
                                            marginLeft: "2.5px"
                                        }}
                                        id="horaSalidaTrabajoNegocio"
                                        name="horaSalidaTrabajoNegocio"
                                        placeholder=""
                                        type="time"
                                        value={cliente.horaSalidaTrabajoNegocio}
                                        onChange={async (event) => {
                                            cambiarDatosCliente(event);
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        cliente.tieneNegocioCliente === 1 &&
                        <>
                            <Row >
                                <DropdownItem divider />
                                <Col>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                            color: colors.azul
                                        }}
                                    >
                                        Análisis del Negocio
                                    </Label>
                                </Col>
                            </Row>
                            <Row>

                                <Col
                                // ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Ventas Semanales
                                        </Label>
                                        <Input
                                            style={{}}
                                            id="ventasSemanalesNegocioCliente"
                                            name="ventasSemanalesNegocioCliente"
                                            placeholder="Texto libre"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.ventasSemanalesNegocioCliente)}
                                            onChange={async (event) => {
                                                const name = "ventasSemanalesNegocioCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                // ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Inversion Semanal
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="inversionSemanalNegocioCliente"
                                            name="inversionSemanalNegocioCliente"
                                            placeholder="Texto libre"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.inversionSemanalNegocioCliente)}
                                            onChange={async (event) => {
                                                const name = "inversionSemanalNegocioCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                // ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            ¿Tu Local Es?
                                        </Label>
                                        <Input
                                            id="descripcionLocalPropioRentado"
                                            name="descripcionLocalPropioRentado"
                                            placeholder=""
                                            type="select"
                                            value={cliente.descripcionLocalPropioRentado}
                                            onChange={async (event) => {
                                                cambiarDatosCliente(event);
                                            }}
                                        >
                                            <option value={""}>Escoge una opción</option>
                                            <option value={"Rentado"}>Rentado</option>
                                            <option value={"Proprio"}>Propio</option>
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col
                                // ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Gastos de Renta de tu Negocio
                                        </Label>

                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="gastosRentaNegocioCliente"
                                            name="gastosRentaNegocioCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.gastosRentaNegocioCliente)}
                                            onChange={async (event) => {
                                                const name = "gastosRentaNegocioCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                // ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Gastos de Agua de tu Negocio
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="gastosAguaNegocioCliente"
                                            name="gastosAguaNegocioCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.gastosAguaNegocioCliente)}
                                            onChange={async (event) => {
                                                const name = "gastosAguaNegocioCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row   >
                                <Col
                                ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Gastos de Luz de tu Negocio
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="gastosLuzNegocioCliente"
                                            name="gastosLuzNegocioCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.gastosLuzNegocioCliente)}
                                            onChange={async (event) => {
                                                const name = "gastosLuzNegocioCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Gastos de Internet de tu Negocio
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="gastosInternetNegocioCliente"
                                            name="gastosInternetNegocioCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.gastosInternetNegocioCliente)}
                                            onChange={async (event) => {
                                                const name = "gastosInternetNegocioCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                ////  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Otros Gastos de tu Negocio
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="gastosOtrosNegocioCliente"
                                            name="gastosOtrosNegocioCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.gastosOtrosNegocioCliente)}
                                            onChange={async (event) => {
                                                const name = "gastosOtrosNegocioCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    ////  md={ "" : "2"}
                                    style={{
                                        maxWidth: "20%"
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Cuanto Tiene Mercancía
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="cuantoTieneMercanciaCliente"
                                            name="cuantoTieneMercanciaCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.cuantoTieneMercanciaCliente)}
                                            onChange={async (event) => {
                                                const name = "cuantoTieneMercanciaCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    ////  md={ "" : "2"}
                                    style={{
                                        maxWidth: "20%"
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            ¿Cuanto Dinero le Deben?
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="cuantoDineroDebenCliente"
                                            name="cuantoDineroDebenCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.cuantoDineroDebenCliente)}
                                            onChange={async (event) => {
                                                const name = "cuantoDineroDebenCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row  >
                                <Col
                                    //  md={ "" : "2"}
                                    style={{
                                        maxWidth: "20%"
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            ¿Cuánto Tiene Efectivo/Ahorro?
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="cuantoTieneEfectivoAhorradoCliente"
                                            name="cuantoTieneEfectivoAhorradoCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.cuantoTieneEfectivoAhorradoCliente)}
                                            onChange={async (event) => {
                                                const name = "cuantoTieneEfectivoAhorradoCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    //  md={ "" : "2"}
                                    style={{
                                        maxWidth: "40%"
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            ¿Cuánto Calcula que Tiene de Ganancia en su Negocio?
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="cuantoTieneGananciaNegocio"
                                            name="cuantoTieneGananciaNegocio"
                                            placeholder="Texto libre"
                                            type="text"
                                            value={cliente.cuantoTieneGananciaNegocio}
                                            onChange={async (event) => {
                                                cambiarDatosCliente(event);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    //  md={ "" : "2"}
                                    style={{
                                        maxWidth: "20%"
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            ¿Tienen Otros Ingresos?
                                        </Label>
                                        <FormGroup>
                                            <Input
                                                style={{
                                                    // width : "900px",
                                                }}
                                                id="tieneOtrosIngresosCliente"
                                                name="tieneOtrosIngresosCliente"
                                                placeholder=""
                                                type="checkbox"
                                                checked={cliente.tieneOtrosIngresosCliente === 1 ? true : false}
                                                onChange={async () => {
                                                    setCliente({
                                                        ...cliente,
                                                        tieneOtrosIngresosCliente: cliente.tieneOtrosIngresosCliente === 1 ? 0 : 1
                                                    });
                                                    components.sleep(0.3).then(() => components.fucusElement("descripcionOtrosIngresosClientes")).catch(() => null);
                                                }}
                                            />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul,
                                                    marginLeft: "5px"
                                                }}
                                            >
                                                Si
                                            </Label>
                                            <Input
                                                style={{
                                                    marginLeft: "15px"
                                                }}
                                                id="tieneOtrosIngresosCliente"
                                                name="tieneOtrosIngresosCliente"
                                                placeholder=""
                                                type="checkbox"
                                                checked={cliente.tieneOtrosIngresosCliente === 1 ? false : true}
                                                onChange={async () => {
                                                    setCliente({
                                                        ...cliente,
                                                        tieneOtrosIngresosCliente: cliente.tieneOtrosIngresosCliente === 1 ? 0 : 1
                                                    });
                                                }}
                                            />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul,
                                                    marginLeft: "5px"
                                                }}
                                            >
                                                No
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                                {
                                    cliente.tieneOtrosIngresosCliente === 1 &&
                                    <Col
                                        style={{
                                            maxWidth: "20%"
                                        }}
                                    >
                                        <FormGroup>
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    color: colors.azul,
                                                }}
                                            >
                                                Describa Sus Otros Ingresos
                                            </Label>
                                            <Input
                                                style={{
                                                    // width : "900px",
                                                }}
                                                id="descripcionOtrosIngresosClientes"
                                                name="descripcionOtrosIngresosClientes"
                                                placeholder=""
                                                type="text"
                                                value={cliente.descripcionOtrosIngresosClientes}
                                                disabled={cliente.tieneOtrosIngresosCliente === 1 ? false : true}
                                                onChange={async (event) => {
                                                    cambiarDatosCliente(event);
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                            <Row >
                                <Col
                                //  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Declaración Patrimonial Vivienda
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="viviendaDeclaracionPatrimonialCliente"
                                            name="viviendaDeclaracionPatrimonialCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.viviendaDeclaracionPatrimonialCliente)}
                                            onChange={async (event) => {
                                                const name = "viviendaDeclaracionPatrimonialCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                //  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Declaración Patrimonial Vehículos
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="vehiculosDeclaracionPatrimonialCliente"
                                            name="vehiculosDeclaracionPatrimonialCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.vehiculosDeclaracionPatrimonialCliente)}
                                            onChange={async (event) => {
                                                const name = "vehiculosDeclaracionPatrimonialCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        // onClick={() => {
                                        //     if (!clienteAceptaPoliticas) {
                                        //         setState({
                                        //             abrirModalAceptoPrivacidadCLiente: true,
                                        //         });
                                        //     }
                                        // }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                //  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Declaracion Patrimonial Equipo
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="mobiliarioEquipoDeclaracionPatrimonial"
                                            name="mobiliarioEquipoDeclaracionPatrimonial"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.mobiliarioEquipoDeclaracionPatrimonial)}
                                            onChange={async (event) => {
                                                const name = "mobiliarioEquipoDeclaracionPatrimonial";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                //  md={ "" : "2"}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Declaracion Patrimonial Otros
                                        </Label>
                                        <Input
                                            style={{
                                                // width : "900px",
                                            }}
                                            id="otrosDeclaracionPatrimonialCliente"
                                            name="otrosDeclaracionPatrimonialCliente"
                                            placeholder="0.00"
                                            type="text"
                                            value={NumeroTools.castFormatoMoneda(cliente.otrosDeclaracionPatrimonialCliente)}
                                            onChange={async (event) => {
                                                const name = "otrosDeclaracionPatrimonialCliente";
                                                let { value } = event.target;
                                                value = value.replace(/[^\d]/g, '');
                                                const sEvent = {
                                                    target: {
                                                        name,
                                                        value
                                                    }
                                                }
                                                cambiarDatosCliente(sEvent);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col
                                    //  md={ "" : "2"}
                                    style={{
                                        maxWidth: "20%"
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Ha Tenido Otros Créditos
                                        </Label>
                                        <FormGroup>
                                            <Input
                                                style={{
                                                    // width : "900px",
                                                }}
                                                id="haTenidoCreditoBancariosCliente"
                                                name="haTenidoCreditoBancariosCliente"
                                                placeholder=""
                                                type="checkbox"
                                                checked={cliente.haTenidoCreditoBancariosCliente === 1 ? true : false}
                                                onChange={async (event) => {
                                                    setCliente({
                                                        ...cliente,
                                                        haTenidoCreditoBancariosCliente: cliente.haTenidoCreditoBancariosCliente === 1 ? 0 : 1
                                                    });
                                                    components.sleep(0.3).then(() => components.fucusElement("institucionDieronCreditoCliente")).catch(() => null);
                                                }}
                                            />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    marginLeft: "5px"
                                                }}
                                            >
                                                Si
                                            </Label>
                                            <Input
                                                style={{
                                                    marginLeft: "15px"
                                                }}
                                                id="haTenidoCreditoBancariosCliente"
                                                name="haTenidoCreditoBancariosCliente"
                                                placeholder=""
                                                type="checkbox"
                                                checked={cliente.haTenidoCreditoBancariosCliente === 1 ? false : true}
                                                onChange={async (event) => {
                                                    setCliente({
                                                        ...cliente,
                                                        haTenidoCreditoBancariosCliente: cliente.haTenidoCreditoBancariosCliente === 1 ? 0 : 1
                                                    });
                                                }}
                                            />
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    marginLeft: "5px"
                                                }}
                                            >
                                                No
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row >
                                <Col
                                    //// md={ "" : "2"}
                                    style={{
                                        maxWidth: "20%",
                                    }}
                                >
                                    <FormGroup>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.azul,
                                            }}
                                        >
                                            Institución Donde le Dieron el Crédito
                                        </Label>
                                        <Input
                                            name="institucionDieronCreditoCliente"
                                            id="institucionDieronCreditoCliente"
                                            value={cliente.institucionDieronCreditoCliente}
                                            disabled={cliente.haTenidoCreditoBancariosCliente === 1 ? false : true}
                                            type="text"
                                            onChange={async (event) => {
                                                cambiarDatosCliente(event);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row >
                        <DropdownItem divider />
                        <Col
                        //// md={ "" : "2"}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: "24px",
                                    marginTop: "20px",
                                    marginBottom: "20px"
                                }}
                            >
                                Referencias De Personas Que No Vivan Con Usted (2 Personas)
                            </Label>
                        </Col>
                    </Row>
                    <Row >
                        <Col
                            // // md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        colors: colors.azul,
                                    }}
                                >
                                    Nombre Completo y Apellido
                                </Label>
                                <Input
                                    name="nombreCompletoPersonaReferencia1Cliente"
                                    id="nombreCompletoPersonaReferencia1Cliente"
                                    value={cliente.nombreCompletoPersonaReferencia1Cliente}
                                    type="text"
                                    onChange={async (event) => {

                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            // md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Parentesco
                                </Label>
                                <Input
                                    name="parentescoPersonaReferencia1Cliente"
                                    id="parentescoPersonaReferencia1Cliente"
                                    value={cliente.parentescoPersonaReferencia1Cliente}
                                    type="text"
                                    onChange={async (event) => {

                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            // // md={ "" : "2"}
                            style={{
                                maxWidth: "40%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Dirección
                                </Label>
                                <Input
                                    name="direccionReferencia1Cliente"
                                    id="direccionReferencia1Cliente"
                                    value={cliente.direccionReferencia1Cliente}
                                    type="text"
                                    onChange={async (event) => {

                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            // // md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Teléfono
                                </Label>
                                <Input
                                    name="numeroTelefonoPersonaReferencia1Cliente"
                                    id="numeroTelefonoPersonaReferencia1Cliente"
                                    value={cliente.numeroTelefonoPersonaReferencia1Cliente}
                                    type="text"
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            cambiarDatosCliente(event);
                                        }

                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            // md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Nombre Completo y Apellido
                                </Label>
                                <Input
                                    name="nombreCompletoPersonaReferencia2Cliente"
                                    id="nombreCompletoPersonaReferencia2Cliente"
                                    value={cliente.nombreCompletoPersonaReferencia2Cliente}
                                    type="text"
                                    onChange={async (event) => {

                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            // md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Parentesco
                                </Label>
                                <Input
                                    name="parentescoPersonaReferencia2Cliente"
                                    id="parentescoPersonaReferencia2Cliente"
                                    value={cliente.parentescoPersonaReferencia2Cliente}
                                    type="text"
                                    onChange={async (event) => {

                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            // md={ "" : "2"}
                            style={{
                                maxWidth: "40%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Direccion
                                </Label>
                                <Input
                                    name="direccionReferencia2Cliente"
                                    id="direccionReferencia2Cliente"
                                    value={cliente.direccionReferencia2Cliente}
                                    type="text"
                                    onChange={async (event) => {

                                        cambiarDatosCliente(event);

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col
                            // md={ "" : "2"}
                            style={{
                                maxWidth: "20%"
                            }}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                    }}
                                >
                                    Telefono
                                </Label>
                                <Input
                                    name="numeroTelefonoPersonaReferencia2Cliente"
                                    id="numeroTelefonoPersonaReferencia2Cliente"
                                    value={cliente.numeroTelefonoPersonaReferencia2Cliente}
                                    type="text"
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        if (!isNaN(value) && value.length <= 10 && value.charAt(value.length - 1) !== " ") {
                                            cambiarDatosCliente(event);
                                        }

                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            backgroundColor: colors.verde_1,
                            borderColor: colors.verde_1,
                            color: colors.blanco,
                            fontWeight: "bold",
                            boxShadow: "none",
                            width: 150,
                        }}
                        onClick={() => {
                            setCliente(ClienteModel.getDataGenericClient());
                        }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        style={{
                            backgroundColor: colors.verde_1,
                            borderColor: colors.verde_1,
                            color: colors.blanco,
                            fontWeight: "bold",
                            boxShadow: "none",
                            width: 150,
                        }}
                        onClick={() => {
                            guardarCliente();
                        }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalSCClientes}
                size={"lg"}
                style={{
                    maxWidth: "90%"
                }}
            >
                <ModalHeader
                    style={{
                        backgroundColor: colors.azul
                    }}
                    close={
                        <Row>
                            <Col style={{ textAlign: "right" }} >
                                <IconButton
                                    style={{
                                        marginRight: 5,
                                        marginLeft: 5,
                                        backgroundColor: "#F2F2F2", color: colors.azul
                                    }}
                                    onClick={() => {
                                        getListarSolicitudesCredito();
                                        toast.info("Tabla actualizada");
                                    }}
                                    title="Actualizar Tabla"
                                >
                                    <Cached />
                                </IconButton>
                                <IconButton
                                    style={{
                                        marginRight: 5,
                                        marginLeft: 5,
                                        backgroundColor: "#F2F2F2", color: colors.azul
                                    }}
                                    onClick={() => {
                                        cargarDatosSC();
                                        setIsOpenModalNuevaSC(true);
                                    }}
                                    title="Nueva Solicitud"
                                >
                                    <Add />
                                </IconButton>
                                <IconButton
                                    style={{
                                        marginRight: 5,
                                        marginLeft: 5,
                                        backgroundColor: "#F2F2F2", color: colors.azul
                                    }}
                                    onClick={() => {
                                        setIsOpenModalSCClientes(false);
                                        setIsOpenModalClienteEdit(true);
                                    }}
                                    title="Cerrar ventana"
                                >
                                    <Close />
                                </IconButton>
                            </Col>
                        </Row>
                    }
                >
                    <Label style={{ fontSize: 24, color: colors.blanco, fontWeight: "bold" }} >
                        Solicitudes de Crédito de {concatenarNombreCompleto(cliente)}
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Table
                            columns={columnsTablaSolicitudesCredito}
                            data={listaSolicitudesCredito}
                            noDataComponent={<p style={{ fontWeight: "bold" }}>No hay solicitudes de crédito pendientes registrados</p>}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalPDF}
                fullscreen
                style={{
                    padding: 0
                }}
            >
                <ModalHeader
                    style={{
                        backgroundColor: "#323639",
                        padding: 10
                    }}
                    close={
                        <Row>
                            <Col>
                                <IconButton
                                    style={{
                                        backgroundColor: "#525659", color: colors.blanco
                                    }}
                                    onClick={() => {
                                        setIsOpenModalPDF(false);
                                    }}
                                    title="Cerrar ventana"
                                >
                                    <Close />
                                </IconButton>
                            </Col>
                        </Row>
                    }
                >
                </ModalHeader>
                <ModalBody style={{ width: "100%", padding: 0, backgroundColor: "#525659" }} >
                    <PDFViewer
                        style={{
                            width: "100%",
                            height: "89vh"
                        }}
                        showToolbar
                    >
                        <SolicitudCreditoPDF cliente={cliente} solicitudCredito={solicitudCredito} />
                    </PDFViewer>
                </ModalBody>
            </Modal>
            <Modal
                isOpen={isOpenModalEditSC}
                size="md"
                onOpened={() => {
                    // components.fucusElement("IdTipoSolicitudCredito");
                    // setState({
                    //     solicitudCredito: {
                    //         IdSolicitudCredito,
                    //         IdCliente: cliente.IdCliente,
                    //         IdTipoSolicitudCredito,
                    //         IdMontotoSolicitudCredito: 0,
                    //         monto: 0,
                    //         montoAprobadoSolicitudCredito: 0,
                    //         IdPlazoPagoSolicitudCredito: 0,
                    //         plazoSolicitudCredito,
                    //         IdFormaPagoSolicitudCredito: 0,
                    //         formaPagoSolicitudCredito,
                    //         IdStatusSolicitudCredito: 0,
                    //         statusSolicitudCredito,
                    //         fechaCreacionSolicitudCredito,
                    //         horaCreacionSolicitudCredito,
                    //         fechaAprobacionRechazadoModificacion,
                    //         horaAprobacionRechazadoModificacion,
                    //     }
                    // });
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: "#F2F2F2", color: colors.azul }}
                            onClick={() => {
                                setIsOpenModalEditSC(false);
                            }}
                        >
                            <Close style={{ color: colors.azul }} />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul
                    }}
                >
                    <Label
                        style={{
                            fontWeight: "bold",
                            color: colors.blanco,
                            fontSize: 28
                        }}
                    >
                        Solicitud de Credito
                    </Label>

                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: "18px"
                            }}
                        >
                            Nombre del Cliente
                        </Label>
                        <br />
                        <Label style={{ fontWeight: "bold" }} >
                            {cliente.nombreCliente} {cliente.apellidoMaternoCliente} {cliente.apellidoMaternoCliente}
                        </Label>
                    </Row>
                    <DropdownItem divider />
                    <Row>

                        <Col>
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul,
                                        fontSize: "18px"
                                    }}
                                >
                                    Estatus de La Solicitud
                                </Label>
                                <Input
                                    type="select"
                                    value={solicitudCredito.IdStatusSolicitudCredito}
                                    onChange={async (event) => {
                                        let { value } = event.target;
                                        setSolicitudCredito({
                                            ...solicitudCredito,
                                            IdStatusSolicitudCredito: parseInt(value)
                                        });
                                        // await setState({
                                        //     solicitudCredito: {
                                        //         ...solicitudCredito,
                                        //         IdStatusSolicitudCredito: value
                                        //     }
                                        // });
                                        // if (value == 2) {
                                        //     await components.fucusElement("montoAprobadoSolicitudCredito");
                                        // }

                                    }}
                                >
                                    <option value={1}>Inicial</option>
                                    <option value={2}>Aprobado</option>
                                    <option value={3}>Rechazado</option>
                                    <option value={4}>Cancelado</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>


                    <FormGroup>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: "18px"
                            }}
                        >
                            Monto Solicitado
                        </Label>
                        <Input
                            id="IdMontotoSolicitudCredito"
                            name="IdMontotoSolicitudCredito"
                            type="text"
                            value={NumeroTools.castFormatoMoneda(solicitudCredito.monto)}
                            disabled
                            style={{
                                backgroundColor: "transparent",
                                cursor: "not-allowed"
                            }}
                        />
                    </FormGroup>
                    {
                        solicitudCredito.IdStatusSolicitudCredito === 2 &&
                        <FormGroup>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    color: colors.azul,
                                    fontSize: "18px"
                                }}
                            >
                                Monto Aprobado
                            </Label>
                            <Input
                                id="montoAprobadoSolicitudCredito"
                                name="montoAprobadoSolicitudCredito"
                                type="text"
                                value={NumeroTools.castFormatoMoneda(solicitudCredito.montoAprobadoSolicitudCredito)}
                                onChange={({ target }) => {
                                    let { value } = target;
                                    value = value.replace(/[^\d]/g, '');
                                    if (value > 100000) return
                                    setSolicitudCredito({
                                        ...solicitudCredito,
                                        montoAprobadoSolicitudCredito: value
                                    });
                                    //cambioSolicitudCredito(event);
                                }}
                            />
                        </FormGroup>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            boxShadow: "none",
                            backgroundColor: colors.azul,
                            color: colors.blanco,
                            fontWeight: "bold",
                        }}
                        disabled={disabledButtonGuardarSC}
                        onClick={async () => {
                            let data = {
                                IdSolicitudCredito: await solicitudCredito.IdSolicitudCredito,
                                IdStatusSolicitudCredito: await solicitudCredito.IdStatusSolicitudCredito,
                                montoAprobadoSolicitudCredito: await solicitudCredito.montoAprobadoSolicitudCredito,
                            };
                            await solicitudCreditoModal.solicitudCreditoAprobarRechazarCancelar(data);
                            await toast.info("Información actualizada");
                            await getListarSolicitudesCredito();
                        }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalTelefonoClientes}
                size={"lg"}
                style={{
                    maxWidth: "80%"
                }}
            >
                <ModalHeader
                    style={{
                        backgroundColor: colors.azul
                    }}
                    close={
                        <Row>
                            <Col style={{ textAlign: "right" }} >
                                <IconButton
                                    style={{
                                        marginRight: 5,
                                        marginLeft: 5,
                                        backgroundColor: "#F2F2F2", color: colors.azul
                                    }}
                                    onClick={() => {
                                        setIsOpenModalTelefonoClientes(false);
                                    }}
                                    title="Cerrar ventana"
                                >
                                    <Close />
                                </IconButton>
                            </Col>
                        </Row>
                    }
                >
                    <Label style={{ fontSize: 24, color: colors.blanco, fontWeight: "bold" }} >
                        Números de Teléfonos de {concatenarNombreCompleto(cliente)}
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <center>
                                        <QRCode value={"tel:" + cliente.numeroTelefonoCliente} />
                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <a
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "22px"
                                            }}
                                            href={`tel:+52${cliente.numeroTelefonoCliente}`}
                                        >
                                            {NumeroTools.formatoNumeroTelefono(cliente.numeroTelefonoCliente)}
                                        </a>

                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "20px"
                                            }}
                                        >
                                            {concatenarNombreCompleto(cliente)}
                                            <br />
                                            Cliente
                                        </Label>
                                    </center>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <center>
                                        <QRCode value={"tel:" + cliente.numeroTelefonoPersonaReferencia1Cliente} />
                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <a
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "22px"
                                            }}
                                            href={`tel:+52${"tel:" + cliente.numeroTelefonoPersonaReferencia1Cliente}`}
                                        >
                                            {NumeroTools.formatoNumeroTelefono(cliente.numeroTelefonoPersonaReferencia1Cliente)}
                                        </a>

                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "20px"
                                            }}
                                        >
                                            {cliente.nombreCompletoPersonaReferencia1Cliente}
                                            <br />
                                            persona Referencia
                                        </Label>
                                    </center>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <center>
                                        <QRCode value={"tel:" + cliente.numeroTelefonoPersonaReferencia2Cliente} />
                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <a
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "22px"
                                            }}
                                            href={`tel:+52${cliente.numeroTelefonoPersonaReferencia2Cliente}`}
                                        >
                                            {NumeroTools.formatoNumeroTelefono(cliente.numeroTelefonoPersonaReferencia2Cliente)}
                                        </a>

                                    </center>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <center>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "20px"
                                            }}
                                        >
                                            {cliente.nombreCompletoPersonaReferencia2Cliente}
                                            <br />
                                            persona Referencia
                                        </Label>
                                    </center>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalNuevaSC}
                size={"md"}
            >
                <ModalHeader
                    style={{
                        backgroundColor: colors.azul
                    }}
                    close={
                        <Row>
                            <Col>
                                <IconButton
                                    style={{
                                        marginRight: 5,
                                        marginLeft: 5,
                                        backgroundColor: "#F2F2F2", color: colors.azul
                                    }}
                                    onClick={() => {
                                        setIsOpenModalNuevaSC(false);
                                    }}
                                    title="Cerrar ventana"
                                >
                                    <Close />
                                </IconButton>
                            </Col>
                        </Row>
                    }
                >
                    <Label
                        style={{
                            fontWeight: "bold",
                            color: colors.blanco,
                            fontSize: 28
                        }}
                    >
                        Nueva Solicitud de Credito
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: "18px"
                            }}
                        >
                            Nombre del Cliente
                        </Label>
                        <br />
                        <Label>
                            {cliente.nombreCliente} {cliente.apellidoMaternoCliente} {cliente.apellidoMaternoCliente}
                        </Label>
                    </Row>
                    <DropdownItem divider />
                    <FormGroup>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: "18px"
                            }}
                        >
                            Tipo de Crédito
                        </Label>
                        <Input
                            id="IdTipoSolicitudCredito"
                            name="IdTipoSolicitudCredito"
                            type="select"
                            value={solicitudCredito.IdTipoSolicitudCredito}
                            onChange={async (event) => {
                                let { name, value } = event.target;
                                await cambioSolicitudCredito(event);
                            }}
                        >
                            <option value={0}>Selecciona una opción</option>
                            <option value={1}>Credito Individual</option>
                            <option value={2}>Credito Grupal</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: "18px"
                            }}
                        >
                            Monto Solicitado
                        </Label>
                        <Input
                            id="IdMontotoSolicitudCredito"
                            name="IdMontotoSolicitudCredito"
                            type="select"
                            value={solicitudCredito.IdMontotoSolicitudCredito}
                            onChange={(event) => {
                                cambioSolicitudCredito(event);
                            }}
                        >
                            <option value={0}>Selecciona una opción</option>
                            {
                                listaMontoPago.map((value) => {
                                    return (
                                        <option value={value.IdMontoCredito}>{parseFloat(value.monto).toLocaleString('es-MX', { style: 'currency', currency: 'MXN', minimumFractionDigits: 2 })} MXN</option>
                                    );
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: "18px"
                            }}
                        >
                            Plazo del Crédito
                        </Label>
                        <Input
                            id="IdPlazoPagoSolicitudCredito"
                            name="IdPlazoPagoSolicitudCredito"
                            type="select"
                            value={solicitudCredito.IdPlazoPagoSolicitudCredito}
                            onChange={(event) => {
                                cambioSolicitudCredito(event);
                            }}
                        >
                            <option value={0}>Selecciona una opción</option>
                            {
                                listaPlazoPagoCredito.map((value) => {
                                    return (
                                        <option value={value.IdPlazoCredito}>{value.plazoCredito} ({value.descripcion})</option>
                                    );
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label
                            style={{
                                fontWeight: "bold",
                                color: colors.azul,
                                fontSize: "18px"
                            }}
                        >
                            Forma de Pago
                        </Label>
                        <Input
                            id="IdFormaPagoSolicitudCredito"
                            name="IdFormaPagoSolicitudCredito"
                            type="select"
                            value={solicitudCredito.IdFormaPagoSolicitudCredito}
                            onChange={(event) => {
                                cambioSolicitudCredito(event);
                            }}
                        >
                            <option value={0}>Selecciona una opción</option>
                            {
                                listaFormasPago.map((formaPago) => {
                                    return (
                                        <option value={formaPago.IdFormaPago}>{formaPago.descripcion}</option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>
                    {
                        disabledButtonGuardarSC ?
                            <>
                                <DropdownItem divider />
                                <FormGroup
                                    style={{
                                        //backgroundColor: "#FFCE38",
                                        height: "auto",
                                        padding: 5
                                    }}
                                >
                                    <Label
                                        style={{
                                            color: "#F5BC02",
                                            fontWeight: "bold",
                                            textShadow: "-1px -1px 0 #846901, 1px -1px 0 #846901, -1px 1px 0 #846901, 1px 1px 0 #846901",
                                        }}
                                    >
                                        Se tiene que llenar todos los campos para poder guardar la solicitud de crédito
                                    </Label>
                                </FormGroup>
                            </>
                            :
                            null
                    }

                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            boxShadow: "none",
                            backgroundColor: colors.azul,
                            color: colors.blanco,
                            fontWeight: "bold",
                        }}
                        // disabled={disabledButtonGuardarSC}
                        onClick={async () => {
                            let data = {
                                ...solicitudCredito,
                                IdCliente: cliente.IdCliente
                            }
                            await solicitudCreditoModel.guardarSolicitudCredito(data);
                            await getListarSolicitudesCredito();
                            toast.success("Solicitud de Crédito Guardada");
                        }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalEscogerTrabajoNegocio}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{
                                backgroundColor: "#F2F2F2",
                                color: colors.azul
                            }}
                            onClick={() => setIsOpenModalEscogerTrabajoNegocio(false)}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul
                    }}
                >
                    <Label style={{ fontSize: 24, color: colors.blanco, fontWeight: "bold" }} >
                        ¿El cliente Trabaja o Tiene Negocio?
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Label style={{ fontSize: 16, color: colors.azul, fontWeight: "bold" }} >
                                Especifica si el cliente es trabajador o si tiene negocio
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 20 }} >
                        <Col style={{ textAlign: "center" }} >
                            <Button
                                style={{
                                    width: "80%",
                                    fontWeight: "bold",
                                    backgroundColor: colors.verde_1,
                                    borderColor: colors.verde_1,
                                    boxShadow: "none"
                                }}
                                onClick={() => {
                                    setCliente({
                                        ...cliente,
                                        esTrabajadorCliente: 1
                                    });
                                    setIsOpenModalEscogerTrabajoNegocio(false);
                                }}
                            >

                                Es Trabajador
                            </Button>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Button
                                style={{
                                    width: "80%",
                                    fontWeight: "bold",
                                    backgroundColor: colors.verde_1,
                                    borderColor: colors.verde_1,
                                    boxShadow: "none"
                                }}
                                onClick={() => {
                                    setCliente({
                                        ...cliente,
                                        tieneNegocioCliente: 1
                                    });
                                    setIsOpenModalEscogerTrabajoNegocio(false);
                                }}
                            >
                                Tiene Negocio
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal >
        </>
    )
}

export default SClientesLista;